import dashboardSampleScreen from "../../assets/img/sample-screens/app-dashboard.png";
import retail from "../../assets/img/photos/home/retail-solutions@2x.png";
import professionalServices from "../../assets/img/photos/home/professional-services-solution@2x.png";
import largeBusiness from "../../assets/img/photos/home/large-business-solution@2x.png";

import flutterwaveLogo from "../../assets/img/payment-providers/flutterwave.svg";
import paystackLogo from "../../assets/img/payment-providers/paystack.svg";
import stripeLogo from "../../assets/img/payment-providers/stripe.svg";
import squadLogo from "../../assets/img/payment-providers/squad.svg";

// icons
import bankCardOutlineIcon from "../../assets/img/icons/bank-card-outline.svg";
import shieldTickOutlineIcon from "../../assets/img/icons/shield-tick-outline.svg";
import receiptOutlineIcon from "../../assets/img/icons/receipt-outline.svg";
import routingOutlineIcon from "../../assets/img/icons/routing-outline.svg";
import messageTickOutlineIcon from "../../assets/img/icons/message-tick-outline.svg";
import userSinalIcon from "../../assets/img/icons/user-signal.svg";
import shoppingCartIcon from "../../assets/img/icons/shopping-cart.svg";
import checkListIcon from "../../assets/img/icons/check-list.svg";
import briefcaseIcon from "../../assets/img/icons/briefcase.svg";
import copySuccessOutlineIcon from "../../assets/img/icons/copy-success-outline.svg";
import stackOutlineIcon from "../../assets/img/icons/stack-outline.svg";
// import xxxxxxx Icon from "../../assets/img/icons/xxxxxxx";

export const images = {
  dashboardSampleScreen,
  retail,
  professionalServices,
  largeBusiness,

  flutterwaveLogo,
  paystackLogo,
  stripeLogo,
  squadLogo,

  bankCardOutlineIcon,
  shieldTickOutlineIcon,
  receiptOutlineIcon,
  routingOutlineIcon,
  messageTickOutlineIcon,
  userSinalIcon,
  shoppingCartIcon,
  checkListIcon,
  briefcaseIcon,
  copySuccessOutlineIcon,
  stackOutlineIcon,
};
