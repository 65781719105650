import { BulletTitleDescription } from "../../components";
import { appConstants } from "../../constants";
import { CheckListItem } from "./CheckListItem";

import { images } from "./images";

export const View = () => {
  return (
    <div className="reports-page">
      <section className="wrapper bg-pale-orange main-section pb-30">
        <div className="container pt-7 pt-md-5 pb-8 h-100">
          <div className="row h-100 gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6 align-self-end mb-n5 mb-md-n10"
              // data-cues="slideInDown"
              // data-group="page-title"
              // data-delay="600"
            >
              <h4 className="text-black-50 fs-16">FEATURES</h4>
              <h3 className="display-1 fw-normal mb-12">
                <img
                  src={images.pieChartIcon}
                  alt="page icon"
                  className="me-3 hero-icon"
                />
                Reports
              </h3>
              <p className="lead fs-16 lh-sm mb-7 pe-md-18 pe-lg-0 pe-xxl-15 text-black">
                SwitchApp’s powerful APIs and software solutions designed to
                help you capture more revenue allow you to accept payments and
                move money globally.
              </p>
              <div>
                <a
                  href={appConstants.appSignInUrl}
                  className="btn btn-md btn-primary me-3 mt-3 rounded"
                >
                  Sign In
                </a>
                <a
                  href={appConstants.appSignUpUrl}
                  className="btn btn-md btn-outline-primary mt-3 rounded"
                >
                  Request Invitation <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* <!-- /column --> */}
            <div
              className="col-lg-6 mb-n18 me-n18"
              // data-cues="slideInDown"
            >
              <div className="position-relative light-gallery-wrapper">
                <figure className="cut-off-container">
                  <img
                    src={images.reportsSampleScreen}
                    alt="reports image"
                    className="hero-image"
                    style={{ width: 1200, maxWidth: "none" }}
                  />
                </figure>
              </div>
              {/* <!-- /div --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-22 mt-md-0">
        <div className="container pt-22  pb-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 ">
            <div className="col-12">
              <h3 className="display-4 fw-normal mb-3">
                Revenue recognition that helps
                <br className="d-none d-lg-block" /> you close the books in
                hours, confidently
              </h3>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <ul className="nav row justify-content-between">
                <BulletTitleDescription
                  title="Reconcile in a Snap"
                  content={
                    <p>
                      Close the books with ease. All payment data flows in and
                      out of our integrated system for simplified
                      reconciliation.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-10"
                  //contentFontSize={14}
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Know where you stand"
                  content={
                    <p>
                      Payments are difficult, but reporting on them should not
                      be. We make it simple to track your transactions so you
                      know how much to expect and when to expect it.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-10"
                  //contentFontSize={14}
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Automate complex revenue recognition"
                  content={
                    <p>
                      SwitchApp Revenue Recognition can handle even the most
                      complicated recognition scenarios, giving you maximum
                      go-to-market flexibility.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  //contentFontSize={14}
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Cohort analysis of customers"
                  content={
                    <p>
                      Gain a thorough understanding of your customers’ values
                      and preferred products to prevent churn and master
                      customer retention.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-10"
                  //contentFontSize={14}
                />
                {/* <!--/column --> */}
              </ul>
              {/* <!--/row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-soft-purple mt-10 mt-md-0">
        <div className="container pt-15 pb-20">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-center">
            <div className="col-lg-6">
              <h3 className="display-4 fw-normal mb-10 pe-xxl-15">
                Maximize your business’s valuation
              </h3>
              <p className="fw-normal text-black">
                Walk into investor meetings with confidence, knowing SwitchApp
                metrics and financial reporting provide a complete picture of
                your business. Monitor the growth of your business and easily
                communicate with key stakeholders using detailed reports that
                are clear and comprehensive.
              </p>
            </div>
            {/* <!-- /column --> */}

            <img
              src={images.maximizeValuation}
              alt="integrate icon"
              className="col-lg-6 one-stop-payment-image"
            />
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light angled upper-end mt-10 mt-md-0">
        <div className="container py-10 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-center">
            <img
              src={images.paymentProviderReports}
              alt="integrate icon"
              className="col-lg-6"
            />
            {/* <!-- /column --> */}

            <div className="col-lg-6">
              <h3 className="display-4 fw-normal mb-10 pe-xxl-15">
                A unified view of all
                <br className="d-none d-lg-block" /> your revenue
              </h3>
              <p className="fw-normal text-black">
                Get a thorough picture of all your revenue, no matter how it was
                earned. With Revenue Recognition, you can include the
                subscriptions, invoices, and payment transactions that are
                already stored in SwitchApp.
              </p>
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper">
        <div className="container py-15">
          <div className="row text-center">
            <div className="col-lg-10">
              <h3 className="display-4 fw-normal mb-3">Real-time Auditing</h3>
              <p className="mb-9 fw-normal text-black">
                Reviewing your finances and getting ready for auditors is
                simple. Each recognized and deferred revenue amount may be
                easily linked to the underlying clients and invoices thanks to
                revenue recognition.
              </p>
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}

          <div className="row gx-md-8 gy-8 mb-14 mb-md-18">
            <div className="col-12">
              <div className="nav row justify-content-between">
                <CheckListItem className="col-12 col-md-6 col-lg-3">
                  Maximize your business’s valuation
                </CheckListItem>
                {/* <!--/column --> */}

                <CheckListItem className="col-12 col-md-6 col-lg-3">
                  Identify which customers
                  <br className="d-none d-lg-block" /> were billed
                </CheckListItem>
                {/* <!--/column --> */}

                <CheckListItem className="col-12 col-md-6 col-lg-3">
                  Keep track of how a<br className="d-none d-lg-block" />{" "}
                  particular customer paid.
                </CheckListItem>
                {/* <!--/column --> */}

                <CheckListItem className="col-12 col-md-6 col-lg-3">
                  Understand how transactions
                  <br className="d-none d-lg-block" /> were categorized
                </CheckListItem>
                {/* <!--/column --> */}
              </div>
              {/* <!--/row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      {/* bg-gradient-gray */}
      {/* <!-- /section --> */}
    </div>
  );
};
