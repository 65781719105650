import { ReactNode } from "react";
import { useTheme } from "../../hooks";

import { View } from "./View";

export interface IBulletTitleDescriptionProps {
  title: ReactNode;
  content: ReactNode;
  containerClassName?: string;
  titleClassName?: string;
  contentClassName?: string;
  contentFontSize?: number;
}

export const BulletTitleDescription = (props: IBulletTitleDescriptionProps) => {
  const { pageTheme } = useTheme();

  return (
    <View {...props} titleClassName={`${props.titleClassName} ${pageTheme}`} />
  );
};
