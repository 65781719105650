import aiForBusinessesBanner from "../../assets/img/sample-screens/ai-for-businesses-3.png";
import aiAssistant from "../../assets/img/photos/ai-for-businesses/interact-naturally-4.png";
import aiAutomation from "../../assets/img/photos/ai-for-businesses/increase-efficiency-ai-0.png";

// icons
import aiForBusinessesIcon from "../../assets/img/icons/ai-for-businesses.png";

export const images = {
  aiForBusinessesBanner,
  aiAssistant,
  aiAutomation,

  // icons
  aiForBusinessesIcon,
};
