import { IAccordionProps } from "./Accordion";

export const View = (props: IAccordionProps) => {
  return (
    <div className="accordion accordion-wrapper" id={props.name}>
      {props.accordionItems.map((item, index) => (
        <div className="card accordion-item mb-0">
          <div className="card-header" id={`${props.name}_heading${index}`}>
            <button
              className="accordion-button"
              data-bs-toggle="collapse"
              data-bs-target={`#${props.name}_${index}`}
              aria-expanded="false"
              aria-controls={`${props.name}_${index}`}
              type="button"
            >
              {" "}
              <div className="d-flex justify-content-between align-items-center">
                <span className="">{item.title} </span>
                <i className="accordion-indicator" />
              </div>
              {/* <i className="icn icn-chevron-right" /> */}
            </button>
          </div>
          {/* <!--/.card-header --> */}

          <div
            id={`${props.name}_${index}`}
            className="accordion-collapse collapse"
            aria-labelledby={`${props.name}_heading${index}`}
            data-bs-parent={`#${props.name}`}
          >
            <div className="card-body">{item.content}</div>
            {/* <!--/.card-body --> */}
          </div>
          {/* <!--/.accordion-collapse --> */}
        </div>
      ))}
      {/* <!--/.accordion-item --> */}
    </div>
  );
};
