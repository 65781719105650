import { IBadgeProps } from "./Badge";

export const View = (props: IBadgeProps) => {
  return (
    // <div
    //   className={`badge border-${props.kind} border-2 ${
    //     props.roundness || ""
    //   } ${props.className}`}
    // >
    //   {props.children}
    // </div>

    <span
      className={`badge border-2 border-${props.kind} text-${props.kind} ${
        props.roundness || ""
      } ${props.className}`}
    >
      {props.children}
    </span>
  );
};
