const gradientColor1 = "#427ffa",
  gradientColor2 = "#0453ef";

export const StackThemedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="87.867"
      height="87.867"
      viewBox="0 0 87.867 87.867"
      className="themed-svg"
    >
      {/* <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.215"
          x2="0.626"
          y2="0.813"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color={gradientColor1} />
          <stop offset="1" stop-color={gradientColor2} />
        </linearGradient>
      </defs> */}
      <g id="layer" transform="translate(-620 -380)">
        <path
          id="Vector"
          d="M33.463,36.4A14.225,14.225,0,0,1,27.9,35.339L6.3,25.747C.805,23.294,0,20,0,18.2s.805-5.089,6.3-7.542L27.9,1.071a15.09,15.09,0,0,1,11.166,0L60.7,10.663C66.157,13.079,67,16.411,67,18.2s-.805,5.089-6.3,7.542L39.064,35.339A13.772,13.772,0,0,1,33.463,36.4Zm0-30.9a8.344,8.344,0,0,0-3.332.586L8.53,15.679C6.3,16.7,5.492,17.729,5.492,18.2s.805,1.538,3,2.526l21.6,9.592a10.019,10.019,0,0,0,6.7,0l21.637-9.592c2.233-.989,3.039-2.05,3.039-2.526s-.805-1.538-3.039-2.526l-21.6-9.592A9.242,9.242,0,0,0,33.463,5.5Z"
          transform="translate(629.702 387.093)"
          // fill="url(#linear-gradient)"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M35.7,25.042a10.077,10.077,0,0,1-4.1-.879L6.736,13.107A12.114,12.114,0,0,1,0,2.746,2.766,2.766,0,0,1,2.746,0,2.766,2.766,0,0,1,5.492,2.746,6.705,6.705,0,0,0,8.97,8.128L33.829,19.184a4.727,4.727,0,0,0,3.734,0L62.422,8.128A6.652,6.652,0,0,0,65.9,2.746a2.746,2.746,0,0,1,5.492,0,12.217,12.217,0,0,1-6.736,10.4L39.8,24.2A10.672,10.672,0,0,1,35.7,25.042Z"
          transform="translate(628.238 417.527)"
          // fill="url(#linear-gradient)"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M35.7,25.079a10.077,10.077,0,0,1-4.1-.879L6.736,13.143A11.371,11.371,0,0,1,0,2.746,2.766,2.766,0,0,1,2.746,0,2.8,2.8,0,0,1,5.492,2.782,5.906,5.906,0,0,0,8.97,8.164L33.829,19.221a4.727,4.727,0,0,0,3.734,0L62.422,8.164A5.868,5.868,0,0,0,65.9,2.782a2.746,2.746,0,1,1,5.492,0,11.371,11.371,0,0,1-6.736,10.4L39.8,24.237A10.672,10.672,0,0,1,35.7,25.079Z"
          transform="translate(628.238 435.796)"
          // fill="url(#linear-gradient)"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H87.867V87.867H0Z"
          transform="translate(620 380)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};
