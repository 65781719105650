import { ReactNode } from "react";
import { View } from "./View";

interface IAccordionItem {
  title: ReactNode;
  content: ReactNode;
  // containerClassName?: string;
}

export interface IAccordionProps {
  name: string;
  accordionItems: IAccordionItem[];
}

export const Accordion = (props: IAccordionProps) => {
  return <View {...props} />;
};
