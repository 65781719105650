import { ReactNode } from "react";

import { ISwCardProps } from "../SwCard/SwCard";
import { View } from "./View";

export interface IGradientCardProps extends ISwCardProps {
  icon: ReactNode;
  linkUrl: string;
  linkText: string;
}

export const GradientCard = (props: IGradientCardProps) => {
  return <View {...props} />;
};
