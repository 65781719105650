export const aboutUsHtml: string = `
<div  class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align: justify;line-height:normal;background:white; font-family:sans-serif;'>

    <p>SwitchApp is a leading provider of innovative solutions designed to help businesses excel. Our comprehensive suite of services address the common challenges faced by businesses, enabling them to streamline operations, improve efficiency, and achieve their goals.</p>
    <p>We provide merchants with simple but powerful APIs and various no-code solutions for advanced business management. SwitchApp also provides solutions for small-to-medium scale merchants especially social media merchants who usually find it difficult to manage online orders, sales and delivery.
    We provide no-code tools that solve these problems.</p>

    <h2>Our Solutions</h2>
    <p>SwitchApp offers a range of solutions tailored to meet the unique needs of businesses:</p>

    <ul>
        <li><strong>Invoice Payments:</strong> Simplify your invoicing process and ensure timely payments with our intuitive invoice payment solutions.</li>
        <li><strong>Payment Links:</strong> Generate secure payment links that make it easy for your customers to complete transactions online.</li>
        <li><strong>Reporting:</strong> Gain valuable insights into your business performance with our advanced reporting tools, helping you make informed decisions.</li>
        <li><strong>AI Solutions:</strong> Harness the power of artificial intelligence to optimize processes, automate tasks, and drive innovation within your organization.</li>
    </ul>

    <h2>Why Choose SwitchApp?</h2>

    <p>At SwitchApp, we are committed to delivering cutting-edge solutions that empower businesses to thrive in today's competitive landscape. With our user-friendly platforms, personalized support, and dedication to innovation, we help our clients achieve greater success and unlock new opportunities for growth.</p>
    <p>Experience the difference with SwitchApp and take your business to new heights.</p>

</div>
`;
