const gradientColor1 = "#427ffa",
  gradientColor2 = "#0453ef";

export const CopySuccessThemedIcon = () => {
  return (
    <svg
      id="vuesax_outline_copy-success"
      data-name="vuesax/outline/copy-success"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      width="87.87"
      height="87.87"
      viewBox="0 0 87.87 87.87"
      className="themed-svg"
    >
      {/* <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.215"
          x2="0.626"
          y2="0.813"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color={gradientColor1} />
          <stop offset="1" stop-color={gradientColor2} />
        </linearGradient>
      </defs> */}
      <g id="copy-success" transform="translate(0 0)">
        <path
          id="Vector"
          d="M20.686,56.749H36.063c14.316,0,20.686-6.371,20.686-20.686V32.036A2.766,2.766,0,0,0,54,29.29H42.654c-11.35,0-15.194-3.844-15.194-15.194V2.746A2.766,2.766,0,0,0,24.713,0H20.686C6.371,0,0,6.371,0,20.686V36.063C0,50.379,6.371,56.749,20.686,56.749ZM51.258,34.782v1.281c0,11.35-3.844,15.194-15.194,15.194H20.686c-11.35,0-15.194-3.844-15.194-15.194V20.686c0-11.35,3.844-15.194,15.194-15.194h1.281v8.6c0,14.316,6.371,20.686,20.686,20.686Z"
          transform="translate(4.577 26.544)"
          // fill="url(#linear-gradient)"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M20.686,56.749H36.063c14.316,0,20.686-6.371,20.686-20.686V20.686C56.749,6.371,50.379,0,36.063,0H20.686C6.371,0,0,6.371,0,20.686V36.063C0,50.379,6.371,56.749,20.686,56.749ZM36.063,5.492c11.35,0,15.194,3.844,15.194,15.194V36.063c0,11.35-3.844,15.194-15.194,15.194H20.686c-11.35,0-15.194-3.844-15.194-15.194V20.686c0-11.35,3.844-15.194,15.194-15.194Z"
          transform="translate(26.544 4.577)"
          // fill="url(#linear-gradient)"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M87.87,87.87H0V0H87.87Z"
          fill="none"
          opacity="0"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M2.706,0h25.26a2.726,2.726,0,0,1,2.706,2.706,2.726,2.726,0,0,1-2.706,2.706H2.706A2.726,2.726,0,0,1,0,2.706,2.726,2.726,0,0,1,2.706,0Z"
          transform="translate(39.583 30.245)"
          // fill="url(#linear-gradient)"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M2.706,0A2.726,2.726,0,0,1,5.413,2.706v25.26a2.726,2.726,0,0,1-2.706,2.706A2.726,2.726,0,0,1,0,27.966V2.706A2.726,2.726,0,0,1,2.706,0Z"
          transform="translate(52.212 17.615)"
          // fill="url(#linear-gradient)"
        />
      </g>
    </svg>
  );
};
