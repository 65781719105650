import { createContext } from "react";
import { appThemes } from "../constants";
import { IThemeContext } from "../interfaces-and-types";

export const ThemeContext = createContext<IThemeContext>({
  headerClassName: "",
  setHeaderClassName: () => {},
  pageTheme: appThemes.lightBlueTheme,
  setPageTheme: () => {},
});
