import { useEffect } from "react";
import { appThemes } from "../../constants";
import { useTheme } from "../../hooks";
import { View } from "./View";

export const Pricing = () => {
  const { setPageTheme } = useTheme();

  useEffect(() => {
    setPageTheme(appThemes.lightBlueTheme);
  }, []);

  return <View />;
};
