import { ReactNode } from "react";

interface ILinkIconProps {
  mainIcon: ReactNode;
  subscriptIcon?: ReactNode;
  iconLeft?: boolean;
  iconClassName?: string;
  subscriptIconClassName?: string;
}

export const LinkIcon = ({
  mainIcon,
  subscriptIcon,
  iconLeft,
  iconClassName,
  subscriptIconClassName,
}: ILinkIconProps) => {
  return (
    <div className=" align-icon--center">
      <div
        className={`${
          iconLeft ? "icon-left" : "icon-right"
        } align-icon--center ${iconClassName || ""}`}
      >
        {mainIcon}
      </div>

      {!subscriptIcon ? (
        <></>
      ) : (
        <div
          className={`${iconLeft ? "icon-left" : "icon-right"} icon-subscript ${
            subscriptIconClassName || ""
          }`}
        >
          {subscriptIcon}
        </div>
      )}
    </div>
  );
};
