import { NavLink } from "react-router-dom";
import { ILinkWithIconProps } from "./LinkWithIcon";

import { LinkIcon } from "./LinkIcon";

export const View = (props: ILinkWithIconProps) => {
  const {
    containerClassName,
    iconLeft,
    iconRight,
    title,
    subtitle,
    hideDivider,
    urlTarget,
  } = props;

  return (
    <div className={`link-with-icon--container ${containerClassName}`}>
      <NavLink
        to={urlTarget}
        className="d-flex link-container align-items--center"
      >
        <div className="d-flex label-container">
          <div className="icon-left">{iconLeft}</div>

          <div className="title-container">
            <span className="title">{title}</span>
            <br />
            {subtitle ? <span className="subtitle">{subtitle}</span> : null}
          </div>
        </div>

        <div className="iconRightContainer">
          {!iconRight ? (
            <LinkIcon mainIcon={<i className="icn-chevron-right"></i>} />
          ) : null}
          {iconRight && <div className="icon-left">{iconRight}</div>}
        </div>
      </NavLink>
    </div>
  );
};
