import { IBulletTitleDescriptionProps } from "./BulletTitleDescription";

export const View = (props: IBulletTitleDescriptionProps) => {
  return (
    <li
      className={`bullet-title-description mb-10 ${props.containerClassName}`}
    >
      <div className="fw-normal">
        <h3 className={`fw-normal mb-3 ${props.titleClassName}`}>
          {props.title}
        </h3>
        <p
          className={`fs-${props.contentFontSize || 16} fw-light ${
            props.contentClassName
          }`}
        >
          {props.content}
        </p>
      </div>
    </li>
  );
};
