import { Route, Routes } from "react-router-dom";

import { Footer } from "../Footer";
import { Header } from "../Header";
import { IAppLayoutViewProps } from "./AppLayout";

import { HomeLayout } from "./HomeLayout";
import { PricingLayout } from "./PricingLayout";
import { ProductsLayout } from "./ProductsLayout";
import {
  AboutUs,
  PrivacyAndCookiePolicy,
  TermsAndConditions,
} from "../../pages";

export const View = ({ themeClassName }: IAppLayoutViewProps) => {
  return (
    <>
      <div className={`content-wrapper ${themeClassName}`}>
        <Header />

        <div className="pb-12" />
        {/* fixed header content displacement helper */}

        <Routes>
          <Route index element={<HomeLayout />} />

          <Route path="/products/*" element={<ProductsLayout />} />
          <Route path="/pricing/*" element={<PricingLayout />} />

          <Route
            path="/privacy-and-cookie/*"
            element={<PrivacyAndCookiePolicy />}
          />
          <Route path="/privacy/*" element={<PrivacyAndCookiePolicy />} />
          <Route path="/cookie-policy/*" element={<PrivacyAndCookiePolicy />} />

          <Route
            path="/terms-and-conditions/*"
            element={<TermsAndConditions />}
          />

          <Route path="/about/*" element={<AboutUs />} />
        </Routes>
      </div>
      {/* <!-- /.content-wrapper --> */}

      <Footer />

      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </>
  );
};
