export const privacyAndCookiePolicyHtml: string = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:SimSun;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Century Gothic";
	panose-1:2 11 5 2 2 2 2 2 2 4;}
@font-face
	{font-family:"\@SimSun";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0in;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
privacy-and-policy-page a:link, privacy-and-policy-page span.MsoHyperlink
	{color:#0563C1;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:#954F72;
	text-decoration:underline;}
p
	{margin-right:0in;
	margin-left:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.UnresolvedMention1
	{mso-style-name:"Unresolved Mention1";
	color:#605E5C;
	background:#E1DFDD;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;
	font-family:"Calibri",sans-serif;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-size:10.0pt;}
 /* Page Definitions */
 @page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US link="#0563C1" vlink="#954F72">

<div class=WordSection1>

<p class=MsoNormal><b><span style='font-size:12.0pt;line-height:107%;
font-family:"Century Gothic",sans-serif'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>Thank you for opting to use our
services.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>SwitchApp Tech Solution Limited
is a Registered Company with registration no RC1927006 and located at No 16b
Alhaji Hussein Sumnonu Street, Lekki Phase 1, Lagos.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>We operate the SwitchApp mobile
application ("App") and the website ("Website"), [together referred to as the
"Sites"]. Except as set in this Privacy Notice, we do not share, or sell, or
disclose to a third party, any personal information collected at the Sites.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>This Privacy Policy ("Policy")
explains how we collect, use, disclose and protect Personal Information (as it
is defined below) of our customers and users. It applies to all Personal
Information collected by us on <a href="https://www.switchappgo.com" target="blank" link="noreferer"><b>https://www.switchappgo.com</b></a> (the "Site"),
the SwitchApp mobile applications, and any other websites or mobile
applications that link to this Policy, as well as during any written,
electronic, and oral communications (collectively, the "Services"). SwitchApp
may collect Personal Information to help you make any request and to improve the
SwitchApp service.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>Our Policy has been developed
to provide a framework for the appropriate level of privacy protection and
represents our commitment to compliance with the Nigerian Data Protection
legislation and any other applicable worldwide data protection laws, to try and
achieve the highest standards in protecting your personal information.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>Your access to and use of our
Services are subject to our Terms and Conditions, which define some of the
terms used throughout this Policy. Please read this Policy carefully to
understand our privacy practices and let us know if you have any questions by
contacting us through (email address). By using our Services, you accept the
Terms and Conditions, and accept our privacy practices described in this
Policy. </span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>Our Services may change from
time to time. As a result, we reserve the right to update or modify this Policy
at any time and from time to time without prior notice. If we make any material
changes to the way we process your Personal Information, we will notify you
before such changes are effective. We recommend you review this Policy
periodically. This Policy became effective on the date indicated at the top of
this page. Your continued use of our Services after any changes or revisions to
this Policy indicates your agreement to the terms of the revised Policy.</span></p>

<p class=MsoListParagraph style='margin-left:0in;text-align:justify;text-justify:
inter-ideograph'><b><span style='font-family:"Century Gothic",sans-serif'>1. Collection
of Personal Information</span></b></p>

<p class=MsoNormal style='margin-left:13.5pt;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>When you
use our Services, we may collect or receive Personal Information about you to
provide our services and operate our business. The types of Personal
Information that we may collect about you are:</span></p>

<p class=MsoListParagraphCxSpFirst style='text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in'><span style='font-family:"Century Gothic",sans-serif'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
style='font-family:"Century Gothic",sans-serif'>Information You Provide to Us
Directly</span></b></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>You may
provide your Personal Information to us when you use our Services, including
when you register with SwitchApp, search our website, contact us with
inquiries, respond to surveys, or otherwise access our Services. This
information may include:</span></p>

<p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
text-justify:inter-ideograph;text-indent:-.25in'><span style='font-family:Symbol'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Century Gothic",sans-serif'>User
Profile</span></b><i><span style='font-family:"Century Gothic",sans-serif'> –</span></i><span
style='font-family:"Century Gothic",sans-serif'>&nbsp;We collect profile
information associated with your Customer SwitchApp account. This includes
information such as name, age, email address, residential address, and phone
number.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:1.0in;text-align:justify;
text-justify:inter-ideograph;text-indent:-.25in'><span style='font-family:Symbol'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Century Gothic",sans-serif'>Payment
Information</span></b><span style='font-family:"Century Gothic",sans-serif'>&nbsp;–
Payment information collected such as bank name and payment method.</span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:1.0in;text-align:justify;
text-justify:inter-ideograph;text-indent:-.25in'><span style='font-family:Symbol'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><b><span style='font-family:"Century Gothic",sans-serif'>Surveys</span></b><span
style='font-family:"Century Gothic",sans-serif'>&nbsp;– From time-to-time, we
invite our customers and users to participate in surveys or contests. When a
customer or a user participates, we may request certain Personal Information
such as name and email address.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>b.&nbsp;<b>Information Received
from You as Part of Your Communications</b></span></p>

<p class=MsoNormal style='margin-left:9.0pt;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>When you
use our Services (for example on our Site), complete electronic forms or
contact us, by online chat, email, phone or text, we may automatically collect
and store certain information about you and the activity you engaged in, for
example: your name and contact information; your order details; information
that you voluntarily provide to us; the nature of your communication; the
purpose of the interaction, and the action we took in response to your inquiry
or request.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Century Gothic",sans-serif'>&nbsp;c.</span></b><span
style='font-family:"Century Gothic",sans-serif'>&nbsp;<b>Information Collected
Automatically</b></span></p>

<p class=MsoNormal style='margin-left:13.5pt;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>We also
may receive and store certain information about you and your device(s)
automatically when you access or use our Site and Services. This information
may include:</span></p>

<ul style='margin-top:0in' type=disc>
 <li class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><i><span
     style='font-family:"Century Gothic",sans-serif'>Technical Information</span></i></b><span
     style='font-family:"Century Gothic",sans-serif'>&nbsp;– We collect
     technical information associated with your activity on our Site and may
     include information related to your browser and operating system, IP address
     (the Internet address of your computer) unique device identifiers, and
     other information such as your device type. We use such information for
     statistical purposes and to administer our Services to a customer.</span></li>
 <li class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><i><span
     style='font-family:"Century Gothic",sans-serif'>Site Usage Information</span></i></b><span
     style='font-family:"Century Gothic",sans-serif'>&nbsp;– We collect information
     to better understand customer traffic patterns and Site usage. This may
     include the webpage that you were visiting before accessing our Site or
     mobile application, the pages or features of our Site or mobile
     application that you browsed to inform us which part of our Site, app and
     Services you visit and how much time you spend there. This information is
     used to analyze and improve our Site and to provide our customers with a
     better user experience.</span></li>
 <li class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><i><span
     style='font-family:"Century Gothic",sans-serif'>Site Preferences</span></i></b><span
     style='font-family:"Century Gothic",sans-serif'>&nbsp;– We collect
     information about your preferences to make your use of the Site more
     productive using cookies.</span></li>
</ul>

<p class=MsoNormal style='margin-left:.5in;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>d.&nbsp;<b>Location Information</b></span></p>

<p class=MsoNormal style='margin-left:13.5pt;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>When you
use the Services, we may collect and store general location information (such
as IP address). If you permit the Services to access location services through
the permission system used by your mobile operating system ("Platform") or
browser, we may also collect and store the precise location of your device when
the SwitchApp website is running in the foreground or background of your
device. You can choose whether to enable the location tracking feature through
the settings on your device or Platform or when prompted by the SwitchApp website.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>&nbsp;<b>e</b>.&nbsp;<b>Information
Related to the Referral Program</b></span></p>

<p class=MsoNormal style='margin-left:13.5pt;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>SwitchApp
allows users to invite friends and family to sign up for our Services. So You
can then direct us to send referral emails to contacts you select in your
address book. By sending a referral, you also consent to SwitchApp sending your
public profile image to the contacts you select to receive a referral link. By
choosing to invite a friend you represent that you have received the friend's
consent to provide his or her name and email address with us. SwitchApp will
use that information to send an email inviting him or her to visit the site and
will store that information to track the success of the referral program and
may send marketing emails. You can also send an SMS with a referral link to
your contacts. If you use this option, you understand that SwitchApp does not
control the timing, content, or recipients of these SMS messages.</span></p>

<p class=MsoNormal style='margin-left:13.5pt;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>Users
may not send, publish, or share any false, misleading, or deceptive
communications in connection with participation in the Referral Program. Such
actions may violate various federal, state, and/or international laws. Users
may not send any referral emails, SMS messages, or other communications to
children under the age of 18 years.</span></p>

<p class=MsoNormal style='margin-left:13.5pt;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>If you
are referred to SwitchApp through one of our referral programs, or if someone
attempts to refer you, we may share your name, whether you have already signed
up for SwitchApp, whether you are a new customer, and whether you have placed a
qualifying order with SwitchApp with the person(s) who referred you.</span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><b><span
style='font-family:"Century Gothic",sans-serif'>&nbsp;f.</span></b><span
style='font-family:"Century Gothic",sans-serif'>&nbsp;<b>Personal Information
from Publicly Available Sources and Third Parties</b></span></p>

<p class=MsoNormal style='text-align:justify;text-justify:inter-ideograph'><span
style='font-family:"Century Gothic",sans-serif'>We may collect or receive
Personal Information about you from publicly available sources, social network
providers, marketing partners, and/or third parties. This may include</span></p>

<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;
margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif'>Use of Your
Personal Information</span></b></p>

<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.5in;
margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>1.1.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
may use the information we collect or receive about you for various purposes:</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To provide you with the Services which you request.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To verify your identity for security purposes.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To market our Sites and Services, and the Products of third-party
Sellers.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>In other ways naturally associated with the circumstances in which
you provided the information. </span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To process your payments, if you purchase any our services, to
provide you with status on services, deal with your enquiries and requests, and
access and handle any complaints.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To facilitate delivery.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To process and answer your inquiries or to contact you to answer
your questions and/or requests.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To develop and improve the products, services, communication
methods and the functionality of our Sites.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>For the purposes of competitions or promotions that you have
entered.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To communicate information to you and to manage your registration
on our Sites and/or subscription to our newsletter or other communications.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To manage our everyday business needs regarding your participation
in our contests, or promotional activities.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To authenticate the identity of individuals contacting us by
telephone, electronic means or otherwise.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>For internal training and quality assurance purposes.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To understand and assess the interests, wants, and changing needs
of consumers, to improve our Sites, our Services, and/or developing new
products and services.</span></p>

<p style='margin-left:.75in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>To provide personalised Services, communications and targeted
advertising as well as product recommendations to you.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>2.2<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>If
you have asked to receive emails or SMS communications from us, we track
whether you open, read or click on the content to see what you are interested
in so that we can give you more content that we think you are more likely to
enjoy. We use this data to profile your likes and dislikes.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>2.3<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
may also use information you have provided to selected third parties and
consented to be shared, like your age, gender, life stage, lifestyle and wider
interests to identify people who we think will have similar interests to you
and who we believe will be interested in similar advertising.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>2.4<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Information
we collect about your computer, which may at times be able to identify you, for
any lawful business purpose, including without limitation to help diagnose
problems with our servers, to gather broad demographic information and to
otherwise administer our Sites.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>2.5<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Information
which does not identify any individual may be used in a general way by us or
third parties, to provide class information, for example relating to
demographics or usage of a particular page or service.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>2.6<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Your
domain name and e-mail address are recognised by our servers and the pages that
you visit are recorded. We shall not under any circumstances, divulge your
e-mail address to any person who is not identified in this Policy. This
information is used:</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
correspond with you or deal with you as you would expect;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>in
a collective way not referable to any particular individual, for the purpose of
quality control and improvement of our Sites;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
send you news about the Services to which you have signed up;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
tell you about other of our Services or services of related web sites.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-22.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>2.8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>You
may opt-out from receiving future promotional information from us or direct
that we do not share your information with any affiliated companies.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-22.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>2.9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
may use, transfer and share aggregated, anonymous data about our users as a
group for any business purpose, such as analysing usage trends and seeking
compatible advertisers and partners.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>3.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Who
will your Personal Information be shared with?</span></b></p>

<p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.25in;
margin-bottom:.0001pt;text-align:justify;text-justify:inter-ideograph;
background:white'><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>You agree that We may disclose Your personal information to the
following persons:</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
our employees and/or third party service providers, such as payment processors
(including their employees and/or third party service providers), to process
use of the Services by You properly and efficiently and in order for them to
interact directly with You via email or any other method for purposes of
sending You marketing material regarding services, new features, special offers
or promotional items offered by them (unless you have opted out from receiving
such marketing material);</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
law enforcement, government officials, fraud detection agencies or other third
parties when We believe in good faith that the disclosure of personal
information is necessary to prevent physical harm or financial loss, to report
or support the investigation into suspected illegal activity, or to investigate
violations of the Terms.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
our service providers (under contract with us) who help with parts of our
business operations (fraud prevention, marketing, technology services etc.).
Our contracts dictate that these service providers may only use your Personal
Information in connection with the services they perform for us and not for
their own benefit;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
third parties' sponsors of promotions (irrespective of whether such promotions
are hosted by us), or otherwise in accordance with the rules applicable to such
promotions. You should carefully review the rules, if any, of each promotion in
which you choose to participate, as they may contain additional important information
about our, a sponsor's and/or other third parties' use of your personal
information;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
third party business partners like manufacturers and Sellers (in which case we
will have agreements in place to secure the confidentiality of your Personal Information);</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
an affiliate or other third party in the event of any reorganization, merger,
sale, joint venture, assignment, transfer or other disposition of all or any
portion of our business, assets or stock (including without limitation in
connection with any insolvency and/or similar proceedings);</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
the general public when you provide a rating or review of service received, you
consent to us using that rating/review as we deem fit, including without
limitation on the Sites, in newsletters, or other marketing material. The name
that will appear next to that review will be your first name, as you would have
provided upon registration. We will not display your surname, nor any of your
contact details, with a rating or a review;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>To
any person other than as described herein if we notify you and you consent to
the sharing;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>If
you provide information to us with a view to it being read, copied, downloaded
or used by other people, we accept no responsibility for what that third party
may do with it. It is up to you to satisfy yourself about the privacy level of
every person who might see your information. If it is available to all the
World, you have no control whatever as to how it is used;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>to
assist in combating fraud, we share information with credit reference agencies,
so far as it relates to clients or customers who instruct their credit card
issuer to cancel payment to us without having first provided an acceptable
reason to us and given us the opportunity to refund their money;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>for
re-marketing. This involves Facebook or some other supplier placing a tag or
marker in order to be able to serve to you an advert for our Services when you
visit some other website;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>When
we receive a complaint, we record all the information you have given to us. we
use that information to resolve your complaint. If your complaint reasonably
requires us to contact some other person, we may decide to give to that other
person some of the information contained in your complaint. It is a matter for
our sole discretion as to whether we do give information, and, if we do, what
that information is. We may also compile statistics showing information
obtained from this source to assess the level of service we provide, but not in
a way that could identify you or any other person.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Your
Personal Information will also be used by us or shared with our sponsors,
advertisers, advertising networks, advertising servers, social media networks,
and analytics companies or other third-parties in connection with marketing,
promotional, data enrichment and other offers, as well as product information,
product development and development and expansion of our Services.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Your
Personal Information will be used by us or shared within our group of companies
for internal reasons, primarily for business and operational purposes.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>As
we continue to develop our business, we may sell or purchase assets,
subsidiaries or business units. In such transactions, your Personal Information
generally is one of the transferred business assets but remains subject to the promises
made in any pre-existing Policy.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>If
another entity acquires us, our businesses or substantially all or part of our
assets, or assets related to our Sites and/ or Services, your Personal
Information will be disclosed to such entity as part of the due diligence
process and will be transferred to such entity as one of the transferred
assets.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>In
the event of insolvency, business rescue or reorganization proceedings being
brought by or against us, all such Personal Information will be considered an
asset of ours and as such it is possible, they will be sold or transferred to third
parties.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
will ensure that all of our employees, third party service providers,
divisions, affiliates and third-party business partners (including their
employees and third party service providers) having access to your personal
information are bound by appropriate and legally binding obligations in
relation to the confidentiality and security of your Personal Information and
are restricted in their processing thereof as per this Policy.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>You may at any time
request:</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>confirmation that we hold your personal information;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>access to your personal information to view and/or update personal
details;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>the identities or categories of third parties to whom we have
disclosed your personal information; or</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>that we correct or delete any personal information that is
incomplete, misleading, inaccurate, excessive or out of date</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>Requests must be made in writing to (email address).</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>4.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Automated
Decision Making</span></b></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>6.1.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
conduct fraud checks on all customers. Where we believe we may detect
fraudulent activity we may block you from using our Sites.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>6.2.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
share your information with and undertake fraud checks on all customers because
this is necessary for us to perform our contracted services to customers, by
ensuring that the services we (and retailers) provide are duly paid for, and
also so that individuals themselves are protected from fraudulent transactions
on their cards.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>6.3.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>You
have certain rights in respect of this activity - please see 'Your Rights'
section below for more information. Our fraud detection is in place to protect all
our customers as well as SwitchApp. You have the right to contest any fraud
decision made about you and to be given more information about why any such
decision was made by contacting us.</span></p>

<p style='margin-left:4.5pt;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><b><span style='font-size:11.0pt;
font-family:"Century Gothic",sans-serif;color:black'>&nbsp;</span></b></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>7.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>What
are your rights?</span></b></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>We provide our customers and their
authorized agents with the ability to access and delete Personal Information.
In order to exercise these rights, you must login to your account to confirm
your identity, which helps us ensure that Personal Information is only made
accessible to appropriate parties. Customers will not receive discriminatory
treatment for exercising their rights and can return to the service after
deleting their information.&nbsp;</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:0in;line-height:18.0pt;background:white'><b><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Your
Rights</span></b></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>This section lists the privacy-related
rights ("Rights") we extend to all SwitchApp user. Your right to know and right
to deletion are not absolute and are subject to certain exceptions. </span></p>

<p style='margin-left:40.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:115%;background:white'><span style='font-size:
10.0pt;line-height:115%;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
style='font-size:11.0pt;line-height:115%;font-family:"Century Gothic",sans-serif;
color:black'>Right to Know</span></b><span style='font-size:11.0pt;line-height:
115%;font-family:"Century Gothic",sans-serif;color:black'>&nbsp;– You have the
right to know the Personal Information we collect, use, disclose, and sell
about you.</span></p>

<p style='margin-left:40.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:115%;background:white'><span style='font-size:
10.0pt;line-height:115%;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
style='font-size:11.0pt;line-height:115%;font-family:"Century Gothic",sans-serif;
color:black'>Right to Deletion</span></b><span style='font-size:11.0pt;
line-height:115%;font-family:"Century Gothic",sans-serif;color:black'>&nbsp;–
You have the right to request that we delete any Personal Information we have
collected from you or maintain about you. We may save Personal Information when
permitted by applicable law including, without limitation, when the information
is needed for a legal purpose. </span></p>

<p style='margin-left:40.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:115%;background:white'><span style='font-size:
10.0pt;line-height:115%;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
style='font-size:11.0pt;line-height:115%;font-family:"Century Gothic",sans-serif;
color:black'>Right to Rectification</span></b><span style='font-size:11.0pt;
line-height:115%;font-family:"Century Gothic",sans-serif;color:black'>&nbsp;–
If you are a registered SwitchApp web user you may modify certain of your
Personal Information by logging into our Site using your username and password
and visiting the "Account" page. Registered SwitchApp user may also access the
"Account" page to manage their subscriptions.</span></p>

<p style='margin-left:40.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:115%;background:white'><span style='font-size:
10.0pt;line-height:115%;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><b><span
style='font-size:11.0pt;line-height:115%;font-family:"Century Gothic",sans-serif;
color:black'>&nbsp;Right to Opt-Out of the Sale of Your Personal Information</span></b><span
style='font-size:11.0pt;line-height:115%;font-family:"Century Gothic",sans-serif;
color:black'>&nbsp;– You have the right to opt-out of having your Personal
Information sold. </span></p>

<p style='margin-left:40.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-9.0pt;line-height:115%;background:white'><span style='font-size:
10.0pt;line-height:115%;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;line-height:115%;font-family:"Century Gothic",sans-serif;
color:black'>&nbsp;<b>Right to Non-Discrimination</b>&nbsp;– SwitchApp will not
discriminate against those who exercise their Rights. Specifically, if you
exercise your Rights, we will not deny you goods or Services, charge you
different prices or rates for our Services and products or provide you
different level or quality of products or Services.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Asserting
Your Rights</span></b></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>If you have a SwitchApp app, you may exercise your right to know
or your right to deletion by&nbsp;<b><u><a href="https://dashboard.switchappgo.com/settings/account-details" target="blank" link="noreferer">clicking here</a></u></b>&nbsp;for
SwitchApp. To verify your identity, you must log in to your account prior to
accessing or deleting your information.</span></p>

<p style='margin-left:45.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>If
you do not have a SwitchApp, you may exercise your right to know or your right
to deletion by emailing your request to us at&nbsp;(<a href="mailto:support@switchappgo.com" target="blank" link="noreferer">support@switchappgo.com</a>),
with the subject line "Non-user Data Request" or calling us at +234-909-246-9047
For safety and to otherwise prevent fraud, we may ask that you provide us with
certain personal information that's reasonably necessary for us to confirm your
identity.</span></p>

<p style='margin-left:45.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:Symbol;color:black'>•<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>You
may also designate an authorized agent to make a request to know or a request
to delete. To be able to act, authorized agents must submit written proof that
they are authorized to act on your behalf or have a power of attorney. We may
deny requests from authorized agents who do not submit proof that they have
been authorized by you to act on their behalf.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>How
We hold personal information securely</span></b></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>8.1.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
will treat Your personal information as strictly confidential, save where we
are entitled to share it as set out in this Policy.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>8.2.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>In
all circumstances the information is held by us on our secure systems. We take
all reasonable and appropriate organisational steps to ensure that your
personal information is kept secure and is protected against unauthorised or
unlawful processing, misuse, unauthorised disclosure, loss, interference,
destruction or damage, alteration, disclosure or access.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>8.3.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Our
security systems are in line with industry standards, and we monitor
developments to ensure that our security systems evolve, as required. We also
test our systems regularly.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>8.4.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Personal
information is destroyed or anonymised when no longer needed or when we are no
longer required by law to retain it (whichever is the latter).</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>8.5.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
will promptly notify you if we become aware of any unauthorised use, disclosure
or processing of your personal information.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>8.6.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
may store your personal information with the web hosting service provider
appointed by us from time to time. The storage may be in Nigeria or in any
other country.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>8.7.</span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Where
storage is in another country, your personal information will be stored in a
jurisdiction that has equivalent, or better, data protection legislation than
Nigeria or with a service provider which is subject to an agreement requiring
it to observe data protection requirements equivalent to or better than those
applicable in Nigeria.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>9.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span id="cookie-policy"
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Use
of Cookies</span></b></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>We use cookies, web beacons, pixels,
session replay/seen capture, and similar technologies to collect information
and personalize your experience with our Services. When we use session replay
technology, we may collect on real-time basis information on how you use and
navigate the Services. This may include mouse movements and how you scroll
through the Services, but we do not collect keystroke data. Additional
information on other technologies we may use is set forth below.</span></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>a.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Cookies</span></b></p>

<p style='margin-left:22.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-4.5pt;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>&nbsp;Cookies are
small web files that a site or its provider transfers to your device's hard
drive through your web browser that enables the site's or provider's system to
recognize your browser and remember certain information.</span></p>

<p style='margin-left:39.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-39.0pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'><span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>i.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>How We Use Cookies</span></b><span style='font-size:11.0pt;
font-family:"Century Gothic",sans-serif;color:black'>&nbsp;– We use first-party
and third-party cookies for the following purposes:</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
10.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>to make our Services function properly;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
10.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>&nbsp;to improve our Services;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
10.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>&nbsp;to recognize you when you return to SwitchApp and to remember
information you have already provided, </span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
10.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>&nbsp;to collect information about your activities over time and
across third party websites or other online services in order to deliver
content and advertising tailored to your interests; and</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><span style='font-size:
10.0pt;font-family:Symbol;color:black'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>&nbsp;to provide a secure browsing experience during your use of
our Services.</span></p>

<p style='margin-left:39.0pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-39.0pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'><span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>ii.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><i><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>How to Manage Cookies&nbsp;</span></i></b><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>" You may disable
the use of cookies by modifying your browser settings. If you choose to disable
cookies you may not be able to fully experience the interactive features of our
Services. For more information on cookies and how to disable cookies visit <a href="https://www.switchappgo.com/cookie-policy" target="blank" link="noreferer"> <i>https://www.switchappgo.com/cookie-policy</i></a>.</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-27.0pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>b.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Web
Beacons</span></b></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>Web beacons, also known as web bugs,
pixel tags, or clear GIFs, are tiny graphics with a unique identifier that may
be included on our Site to deliver or communicate with cookies, in order to
track and measure the performance of our Site and Services, monitor how many
web visitors we have, and to monitor the effectiveness of our advertising.
Unlike cookies, which are stored on the user's hard drive, web beacons are
typically embedded invisibly on webpages (or in an email).</span></p>

<p style='margin-left:31.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-40.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>c.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>Interest-Based Advertising</span></b></p>

<p style='margin-left:31.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-4.5pt;line-height:18.0pt;background:white'><span style='font-size:
11.0pt;font-family:"Century Gothic",sans-serif;color:black'>&nbsp;We (or our
service providers) may use the information we collect, for instance, IP
addresses and unique mobile device identifiers, to locate or try to locate the
same unique users across multiple browsers or devices (such as smartphones,
tablets, or computers), or work with providers that do this, in order to better
tailor content and features and provide you with a seamless experience across
devices. If you wish to opt out of cross device tracking for purposes of
interest-based advertising, you may do so through your device settings. We may
also use cross-device targeting to help identify our users and serve
advertising. This type of advertising is often called "interest-based" or
"personalized" advertising–and it occurs on mobile apps, "cross-app"
advertising.</span></p>

<p style='margin-left:31.5pt;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>Please note that even if you opt-out,
you may still receive advertisements from us. However, in that case, the
advertising will not be tailored to your interests. Also, we do not control any
of the above opt-out links or whether any particular company chooses to
participate in these opt-out programs.</span></p>

<p style='margin-left:31.5pt;text-align:justify;text-justify:inter-ideograph;
text-indent:-40.5pt;line-height:18.0pt;background:white'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>d.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>Mobile Applications</span></b></p>

<p style='margin-left:31.5pt;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>Depending on your permissions, we may
receive your Personal Information from your Internet service and mobile device
providers. Users of mobile devices&nbsp;who do not want to receive
interest-based advertising may opt-out in several ways. Each operating system,
(iOS for Apple phones, Android for Android devices, and Windows for Microsoft
devices) provides its own instructions on how to prevent the delivery of
tailored in-application advertisements. You should view your device or system
"Settings" to determine how you can opt out of use of your device ID for
"cross-app" personalized advertising.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>10.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Disclaimer
and Indemnity</span></b></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>Whilst we will do all things
reasonably necessary to protect your rights of privacy, we cannot guarantee or
accept any liability whatsoever for unauthorised or unlawful disclosures of
your personal information, whilst in our possession, made by third parties who
are not subject to our control, unless such disclosure is as a result of our
gross negligence or noncompliance with applicable laws.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>If you disclose your personal
information to a third party, such as an entity which operates a website or
mobile application linked to the Sites or anyone other than SwitchApp, SWITCHAPP
SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE, HOWSOEVER ARISING, SUFFERED BY YOU
AS A RESULT OF THE DISCLOSURE OF SUCH INFORMATION TO THE THIRD PARTY. This is
because we do not regulate or control how that third party uses your personal
information. You should always ensure that you read the privacy notice of any
third party.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>11.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Age
Restriction</span></b></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>The Sites are not directed to and/or
intended for use by individuals under the age of 18 years and We request that
such individuals not use the Sites and not provide personal information through
the Sites.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>Persons under the age of 18 years are
not allowed to register for the Service or use the Sites in anyway.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>If a user attempts to register and
inputs a birthdate validating them younger than 18 years of age, their personal
information is not stored and they are directed off the platform.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in'><b><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>12.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Security</span></b></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:115%'><span style='font-size:11.0pt;line-height:115%;font-family:
"Century Gothic",sans-serif;color:black'>SwitchApp has implemented
administrative, technical, and physical security controls that are designed to
safeguard Personal Information. However, no online activity is ever fully
secure or error-free. While we strive to protect your information, we cannot
guarantee that your Personal Information is absolutely secure. Please keep this
in mind when disclosing any information on the SwitchApp website.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:115%'><span style='font-size:11.0pt;line-height:115%;font-family:
"Century Gothic",sans-serif;color:black'>Please recognize that protecting your
Personal Information is also your responsibility. We urge you to take every precaution
to protect your information when you are on the Internet, or when you
communicate with us and with others&nbsp;through the Internet. If you have
reason to believe that your interaction with us is no longer secure (for
example, if you feel that the security of your account might have been
compromised), or if you suspect someone else is using your account, please let
us know immediately by contacting us as indicated in the Contact Us section
below.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in'><b><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>13.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Data
Retention</span></b></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>We
will not retain your information for any longer than we think is necessary. Information
that we collect will be retained for as long as needed to fulfill the purposes
outlined in the 'Use of </span><span lang=EN-GB style='font-size:11.0pt;
font-family:"Century Gothic",sans-serif;color:black'>personal </span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>information'
section above, in line with our legitimate interest or for a period
specifically required by applicable regulations or laws, such as retaining the
information for regulatory reporting purposes.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>When
determining the relevant retention periods, we will take into account factors
including:</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt'><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>our
contractual obligations and rights in relation to the information involved;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt'><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>legal
obligation(s) under applicable law to retain data for a certain period of time;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt'><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>statute
of limitations under applicable law(s);</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt'><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>our
legitimate interests where we have carried out balancing tests (see section on
'How we use your personal information' above);</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt'><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>e)<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>potential
disputes;</span></p>

<p style='margin-left:.5in;text-align:justify;text-justify:inter-ideograph;
text-indent:-13.5pt'><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>f)<span style='font:7.0pt "Times New Roman"'> </span></span><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>guidelines
issued by relevant data protection authorities.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Otherwise,
we securely erase your information where we no longer require your information
for the purposes collected.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in'><b><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>14.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Third-Party
Sites</span></b></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph'><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Some
of the functionality may be provided by third parties and the Services may link
to other third-party websites that are not controlled by SwitchApp. These third
parties are not under our control and SwitchApp is not responsible for their
privacy policies or practices. If you provide any Personal Information to any
third party or through any such third-party website, we recommend that you
familiarize yourself with the privacy policies and practices of that third
party.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in'><b><span style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;
color:black'>15.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Social
Media Usage</span></b></p>

<p class=MsoListParagraphCxSpFirst style='margin-left:.25in;text-align:justify;
text-justify:inter-ideograph;text-indent:-27.0pt'><span style='font-family:
"Century Gothic",sans-serif'>15.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Century Gothic",sans-serif'>You may
choose to enable or log in to our Services via various online services,
including social networking services like Facebook and Google. Our Services
also may enable you to access social networking services such as Facebook,
Twitter, or Instagram (collectively, "Social Network") directly or indirectly
through our Services.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:.25in;text-align:justify;
text-justify:inter-ideograph;text-indent:-27.0pt'><span style='font-family:
"Century Gothic",sans-serif'>15.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Century Gothic",sans-serif'>When you
link a Social Network account to SwitchApp or log into our Services using your
Social Network account, we may collect relevant Personal Information necessary
to enable our Services to access that Social Network and your information
contained within that Social Network. We also may share your information with
the operator of that Social Network to facilitate or enhance delivery of that
Social Network or other services to you. A Social Network may provide us with
access to certain information that you have provided to them, and we will use,
store, and disclose such information in accordance with this Policy.
Additionally, a Social Network may collect Personal Information and information
about your use of our Site and Services automatically. </span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:.25in;text-align:justify;
text-justify:inter-ideograph;text-indent:-27.0pt'><span style='font-family:
"Century Gothic",sans-serif'>15.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span><span style='font-family:"Century Gothic",sans-serif'>The way a
Social Network collects, uses, stores, and discloses your information is
governed by the policies of such third parties and SwitchApp shall have no
liability or responsibility for the privacy practices or other actions of any
Social Network that may be enabled within our Services.</span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-left:22.5pt;text-align:justify;
text-justify:inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpLast style='margin-left:.25in;text-align:justify;
text-justify:inter-ideograph;text-indent:-.25in'><b><span style='font-family:
"Century Gothic",sans-serif'>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;
</span></span></b><b><span style='font-family:"Century Gothic",sans-serif'>International
Users</span></b></p>

<p class=MsoNormal style='margin-left:.25in;text-align:justify;text-justify:
inter-ideograph'><span style='font-family:"Century Gothic",sans-serif'>SwitchApp
is based in Nigeria and, regardless of where you use our Services, the
information collected as part of that use will be transferred to and maintained
on servers located in Nigeria. Any information we obtain about you will be
stored in accordance with Nigeria Data Protection laws, regulations, and
standards, which may not be equivalent to the laws in your country of
residence. By using our Services, you consent to this collection, transfer,
storage, and processing of information to and in Nigeria.</span></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
text-indent:-.25in;line-height:18.0pt;background:white'><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>17.<span
style='font:7.0pt "Times New Roman"'>&nbsp; </span></span></b><b><span
style='font-size:11.0pt;font-family:"Century Gothic",sans-serif;color:black'>Complaints</span></b></p>

<p style='margin-left:.25in;text-align:justify;text-justify:inter-ideograph;
line-height:18.0pt;background:white'><span style='font-size:11.0pt;font-family:
"Century Gothic",sans-serif;color:black'>If you have any questions or
complaints regarding this Policy/Privacy practices, please contact us by email
at <a href="mailto:support@switchappgo.com" target="blank" link="noreferer">support@switchappgo.com</a>.</span></p>

</div>

</body>

</html>

`;
