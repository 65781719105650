import { useScrollToTopOnNewPage, useTheme } from "../../hooks";
import { View } from "./View";

export interface IAppLayoutViewProps {
  themeClassName: string;
}

export const AppLayout = () => {
  const { pageTheme } = useTheme();

  useScrollToTopOnNewPage();

  return <View themeClassName={pageTheme} />;
};
