import { Route, Router, Routes } from "react-router-dom";

import {
  AiForBusinesses,
  FraudsAndRisks,
  Invoices,
  PaymentLinks,
  Payments,
  Reports,
  Subscriptions,
} from "../../pages";

export const ProductsLayout = () => {
  return (
    <Routes>
      <Route index element={<Payments />} />

      <Route path="/ai-for-businesses/*" element={<AiForBusinesses />} />
      <Route path="/payments/*" element={<Payments />} />
      <Route path="/frauds-and-risks/*" element={<FraudsAndRisks />} />
      <Route path="/reports/*" element={<Reports />} />
      <Route path="/invoices/*" element={<Invoices />} />
      <Route path="/payment-links/*" element={<PaymentLinks />} />
      <Route path="/subscriptions/*" element={<Subscriptions />} />
    </Routes>
  );
};
