import { ReactNode } from "react";

import { View } from "./View";

export interface ILinkWithIconProps {
  iconLeft: ReactNode;
  iconRight?: ReactNode;
  title: string;
  subtitle?: string;
  hideDivider?: boolean;
  urlTarget: string;
  // disabled?: boolean;
  containerClassName?: string;
}

export const LinkWithIcon = (props: ILinkWithIconProps) => {
  return <View {...props} />;
};
