const productsRouteRoot = "/products";

export const productsRoute = {
  aiForBusinesses: `${productsRouteRoot}/ai-for-businesses`,
  payments: `${productsRouteRoot}/payments`,
  subscriptions: `${productsRouteRoot}/subscriptions`,
  invoices: `${productsRouteRoot}/invoices`,
  fraudsAndRisks: `${productsRouteRoot}/frauds-and-risks`,
  paymentLinks: `${productsRouteRoot}/payment-links`,
  reports: `${productsRouteRoot}/reports`,
  // xxxxxxxx: `${productsRouteRoot}/xxxxxxxx`,
};
