import { useMemo, useState } from "react";
import { appThemes } from "../constants";
import { ThemeContext } from "./ThemeContext";

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [headerClassName, setHeaderClassName] = useState<string>("");

  const [pageTheme, setPageTheme] = useState<appThemes>(
    appThemes.lightBlueTheme
  );

  const memoedThemeContext = useMemo(
    () => ({
      // header
      headerClassName,
      setHeaderClassName,

      // page
      pageTheme,
      setPageTheme,
    }),
    [headerClassName, pageTheme]
  );

  return (
    <ThemeContext.Provider value={memoedThemeContext}>
      {children}
    </ThemeContext.Provider>
  );
}
