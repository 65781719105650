import { ReactNode } from "react";
import { View } from "./View";

export interface ISwCardProps {
  title?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const SwCard = (props: ISwCardProps) => {
  return <View {...props} />;
};
