export enum appThemes {
  lightBlueTheme = "light-blue-theme",
  navyTheme = "navy-theme",
  orangeTheme = "orange-theme",
  greenTheme = "green-theme",
  cyanTheme = "cyan-theme",
  redTheme = "red-theme",
  pinkTheme = "pink-theme",
  purpleTheme = "purple-theme",
  brownTheme = "brown-theme",
  violetTheme = "violet-theme",
  yellowTheme = "yellow-theme",
}
