import { Route, Router, Routes } from "react-router-dom";

import { Home } from "../../pages";

export const HomeLayout = () => {
  return (
    <Routes>
      <Route index path="/*" element={<Home />} />
    </Routes>
  );
};
