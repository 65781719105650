import { appConstants } from "../../constants";
import { FooterApiSection } from "./FooterApiSection";
import { FooterFirstRow } from "./FooterFirstRow";
import { FooterLinksRow } from "./FooterLinksRow";

export const View = () => {
  return (
    <>
      <FooterApiSection />

      <footer className="bg-dark text-inverse">
        <div className="container pt-10 pt-md-15 pb-13 pb-md-15">
          <FooterFirstRow />

          <FooterLinksRow />

          <hr className="mb-4" />
          <div className="row gy-6 gy-lg-0">
            <div className="col-lg-6">
              © {appConstants.appFooterYear}{" "}
              <span>{appConstants.appCompanyName}</span>. All rights reserved.
            </div>

            <div className="col-lg-2 offset-lg-4 float-right">
              {/* <div className="widget">
                <nav className="nav social social-white">
                  <a href="#">
                    <i className="uil uil-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="uil uil-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="uil uil-dribbble"></i>
                  </a>
                  <a href="#">
                    <i className="uil uil-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="uil uil-youtube"></i>
                  </a>
                </nav>
              </div> */}
              {/* <!-- /.widget --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
        </div>
        {/* {/* <!-- /.container --> */}
      </footer>
    </>
  );
};
