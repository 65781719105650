import { AppLayout, JqueryScripts } from "./components";

import "./app.scss";
import { ThemeProvider } from "./contexts";

function App() {
  return (
    <>
      <ThemeProvider>
        <AppLayout />
      </ThemeProvider>
    </>
  );
}

export default App;
