import fraudsAndRisksSampleScreen from "../../assets/img/sample-screens/frauds-and-risks.png";
import fullyProtected from "../../assets/img/photos/frauds-and-risks/fully-protected.png";
import fraudSecure from "../../assets/img/photos/frauds-and-risks/fraud-secure.svg";

// icons
import fraudNoteIcon from "../../assets/img/icons/fraud-note.svg";
import backgroundGraphicIcon from "../../assets/img/icons/background-graphic-cut-lower-bottom.svg";

export const images = {
  fraudsAndRisksSampleScreen,
  fullyProtected,
  fraudSecure,

  // icons
  fraudNoteIcon,
  backgroundGraphicIcon,
};
