import { IGradientCardProps } from "./GradientCard";

export const View = (props: IGradientCardProps) => {
  return (
    <div className={`card gradient-card ${props.className}`}>
      <div className="card-body">
        <div className="gradient-card-icon mb-5">{props.icon}</div>
        {props.title ? <h2 className="text-black">{props.title}</h2> : null}
        {props.children}

        <p className="gradient-card-link mt-5">
          <a target="_blank" rel="noreferrer" href={props.linkUrl}>
            {props.linkText}
          </a>
        </p>
      </div>
      {/* <!--/.card-body --> */}
    </div>
  );
};
