import { appConstants } from "../../constants";
import { images } from "./images";

export const FooterFirstRow = () => {
  return (
    <div className="row gy-6 gy-lg-0">
      <div className="col-md-6 mb-3">
        <div className="widget">
          <h4 className="widget-title text-white mb-3">
            Scale your business with SwitchApp.
          </h4>
          <p>
            SwitchApp is an all-in-one platform has everything you need to grow
            your business, from subscription billing and global payments to
            simplified invoicing and global compliance. To learn more, schedule
            a free consultation.
          </p>

          <a
            href={appConstants.appSignUpUrl}
            referrerPolicy="no-referrer"
            className="btn btn-md btn-secondary me-3 mt-3 rounded text-black"
          >
            Request Invitation
          </a>
          <a
            href={`${appConstants.appWebsiteHomepageUrl}/products/payments`}
            className="btn btn-md btn-outline-secondary mt-3 rounded text-white"
          >
            See how it works <i className="fa fa-long-arrow-right"></i>
          </a>
        </div>
        {/* <!-- /.widget --> */}
      </div>
      {/* <!-- /column --> */}

      <div className="col-md-6 mb-3">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-semi-transparent">
              <div className="card-body py-8 px-5">
                <img
                  className="mb-4"
                  src={images.shopIcon}
                  alt="shop icon"
                  style={{ width: 50 }}
                />
                <h3 className="text-white">Do you own a business?</h3>
                <p className="fs-14">
                  Production-ready, simple & well documented app for integrating
                  payments on your websites and mobile app.
                </p>

                <div>
                  <a
                    href={`${appConstants.appDashboardUrl}/signup`}
                    className=""
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /column --> */}

          <div className="col-md-6 mt-5 mt-md-0">
            <div className="card card-semi-transparent">
              <div className="card-body py-8 px-5">
                <img
                  className="mb-4"
                  src={images.terminalIcon}
                  alt="terminal icon"
                  style={{ width: 50 }}
                />
                <h3 className="text-white">Are you a Developer??</h3>
                <p className="fs-14">
                  Get up and running with SwitchApp. Integrate the best payment
                  solution API in minutes without hassles.
                </p>

                <div>
                  <a href={`${appConstants.apiDocumentationUrl}`} className="">
                    View documentation
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /column --> */}
        </div>
      </div>
      {/* <!-- /column --> */}
    </div>
  );
};
