import { Route, Router, Routes } from "react-router-dom";

import { Pricing } from "../../pages";

export const PricingLayout = () => {
  return (
    <Routes>
      <Route index element={<Pricing />} />
      <Route path="/pricing/*" element={<Pricing />} />
    </Routes>
  );
};
