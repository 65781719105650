import { BulletTitleDescription, SwCard } from "../../components";
import { appConstants } from "../../constants";
import { CheckListItem } from "./CheckListItem";

import { images } from "./images";

export const View = () => {
  return (
    <div className="invoices-page">
      <section className="wrapper bg-soft-green main-section pb-30">
        <div className="container pt-7 pt-md-5 pb-8 h-100">
          <div className="row h-100 gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6 align-self-end mb-n5 mb-md-n10"
              // data-cues="slideInDown"
              // data-group="page-title"
              // data-delay="600"
            >
              <h4 className="text-black-50 fs-16">FEATURES</h4>
              <h3 className="display-1 fw-normal mb-12">
                <img
                  src={images.noteGreenIcon}
                  alt="page icon"
                  className="me-3 hero-icon"
                />
                Invoices
              </h3>
              <p className="lead fs-16 lh-sm mb-7 pe-md-18 pe-lg-0 pe-xxl-15 text-black">
                SwitchApp's Invoice Payment Processing, allows your global
                customers to pay invoices online using Payment Links, customised
                payment pages, and Virtual Terminal.
              </p>
              <div>
                <a
                  href={appConstants.appSignInUrl}
                  className="btn btn-md btn-primary me-3 mt-3 rounded"
                >
                  Sign In
                </a>
                <a
                  href={appConstants.appSignUpUrl}
                  className="btn btn-md btn-outline-primary mt-3 rounded"
                >
                  Request Invitation <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* <!-- /column --> */}
            <div
              className="col-lg-6 mb-n18 me-n18"
              // data-cues="slideInDown"
            >
              <div className="position-relative light-gallery-wrapper">
                <figure className="cut-off-container">
                  <img
                    src={images.invoicesSampleScreen}
                    alt=""
                    className="hero-image"
                    style={{ width: 1200, maxWidth: "none" }}
                  />
                </figure>
              </div>
              {/* <!-- /div --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-22 mt-lg-0">
        <div className="container pt-20 pt-md-17 pb-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 ">
            <div className="col-12">
              <h3 className="display-4 fw-normal mb-3">
                Get paid faster with online invoice payments.
              </h3>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <div className="row">
                <div className="col-md-6 col-lg-3 mb-5">
                  <SwCard className="bg-pale-green">
                    <div className="mb-2">
                      <img
                        src={images.globalRefreshOutlineIcon}
                        alt="note icon"
                        className="mb-3 card-icon"
                      />
                    </div>
                    <h4 className="mb-3 ">Global Payments & Currencies</h4>
                    <p className="fw-light">
                      Allow your consumers to pay in the currencies and payment
                      methods of their choice. You get a global solution that
                      adapts to your business’s changing needs.
                    </p>
                  </SwCard>
                </div>
                {/* <!--/column --> */}

                <div className="col-md-6 col-lg-3 mb-5">
                  <SwCard className="bg-pale-green">
                    <div className="mb-2">
                      <img
                        src={images.cardsOutlineIcon}
                        alt="page icon"
                        className="mb-3 card-icon"
                      />
                    </div>
                    <h4 className="mb-3 ">More payment options</h4>
                    <p className="fw-light">
                      Make it easier for customers by accepting payments with
                      card, bank transfer, and other payment methods and
                      providers.
                    </p>
                  </SwCard>
                </div>
                {/* <!--/column --> */}

                <div className="col-md-6 col-lg-3 mb-5">
                  <SwCard className="bg-pale-green">
                    <div className="mb-2">
                      <img
                        src={images.clipboardTickOutlineIcon}
                        alt="page icon"
                        className="mb-3 card-icon"
                      />
                    </div>
                    <h4 className="mb-3 ">Easy to get started</h4>
                    <p className="fw-light">
                      Create, customize, and send a SwitchApp-hosted or PDF
                      invoice in minutes—with no code required.
                    </p>
                  </SwCard>
                </div>
                {/* <!--/column --> */}

                <div className="col-md-6 col-lg-3 mb-5">
                  <SwCard className="bg-pale-green">
                    <div className="mb-2">
                      <img
                        src={images.statusUpOutlineIcon}
                        alt="page icon"
                        className="mb-3 card-icon"
                      />
                    </div>
                    <h4 className="mb-3 ">Scalable to match your demands</h4>
                    <p className="fw-light">
                      SwitchApp’s APIs help in the automation of invoicing
                      workflows and accounts receivable processes. Use email
                      reminders and aging reports to collect unpaid invoices.
                    </p>
                  </SwCard>
                </div>
                {/* <!--/column --> */}
              </div>
              {/* <!--/row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper mt-10 mt-md-0 position-relative">
        <div className="container py-10 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-end">
            <div className="col-lg-5">
              <h3 className="display-4 fw-normal mb-5 pe-xxl-15">
                Generate an invoice
              </h3>
              <p className="fw-normal text-black">
                To create an invoice, include the product and the customer’s
                details. Set a tax rate manually or have sales taxes taken care
                of automatically. Create a statement that reflects your brand.
                To avoid starting over with each invoice, save your information
                in the Dashboard.
              </p>

              <div className="col-lg-10">
                <h4 className="fs-20 mb-3">Each invoice includes</h4>
              </div>
              {/* <!-- /column --> */}

              <div className="col-12 ps-3">
                <div className="row">
                  <CheckListItem className="col-12 col-md-6">
                    Product details
                  </CheckListItem>
                  {/* <!--/column --> */}

                  <CheckListItem className="col-12 col-md-6">
                    Test Environment
                  </CheckListItem>
                  {/* <!--/column --> */}

                  <CheckListItem className="col-12 col-md-6">
                    Customer information
                  </CheckListItem>
                  {/* <!--/column --> */}

                  <CheckListItem className="col-12 col-md-6">
                    Different Payment Method
                  </CheckListItem>
                  {/* <!--/column --> */}

                  <CheckListItem className="col-12 col-md-6">
                    Memo
                  </CheckListItem>
                  {/* <!--/column --> */}
                </div>
                {/* <!--/row --> */}
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!-- /column --> */}

            <img
              src={images.generateInvoice}
              alt="subscription details"
              className="col-lg-7 mt-lg-2"
            />
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}

        {/* <div className="w-100 h-100 bg-blurred-ellipse position-absolute" /> */}
        {/* blurred background */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-10 mt-md-0">
        <div className="container py-10 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-end">
            <div className="col-lg-6">
              <h3 className="display-4 fw-normal mb-5 pe-xxl-15">
                Operational simplicity
              </h3>
              <p className="fw-normal text-black">
                Directly from the Dashboard, you can manage invoices and examine
                in-depth reports. Reduce time spent tracking and collecting
                payment for invoices by automating accounts receivable
                processes. You can also enable your customers to self-manage
                their invoices and subscriptions from a secure, prebuilt
                customer portal.
              </p>
            </div>
            {/* <!-- /column --> */}

            <img
              src={images.operationalSimplicity}
              alt="payment options"
              className="col-lg-12 mt-lg-2"
            />
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-soft-green angled upper-end  mt-n10 mt-md-n18 mt-lg-n22">
        <div className="container py-10 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-end">
            <div className="col-lg-6">
              <h3 className="display-4 fw-normal mb-10 pe-xxl-15">
                Diverse Payment Options for Worldwide Growth
              </h3>
              <p className="fw-normal text-black">
                You can reassure your international customers by providing
                localized payment experiences using their preferred payment
                methods, while having access to a variety of payment providers
                and currencies. As you expand into new markets, our platform
                makes it simple to add or modify payment options so you can meet
                the preferences of your customers wherever and however they
                prefer to pay.
              </p>
            </div>
            {/* <!-- /column --> */}

            <img
              src={images.paymentOptions}
              alt="payment options"
              className="col-lg-6 mt-lg-2"
            />
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      {/* bg-gradient-gray */}
      {/* <!-- /section --> */}
    </div>
  );
};
