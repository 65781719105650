import { ReactNode } from "react";
import { TColorTheme } from "../../interfaces-and-types";
import { View } from "./View";

export interface IBadgeProps {
  children: ReactNode;
  className?: string;
  kind?: TColorTheme;
  roundness?: null | "rounded" | "rounded-pill";
}
export const Badge = (props: IBadgeProps) => {
  const { roundness = "rounded-pill" } = props;

  return <View {...props} roundness={roundness} />;
};
