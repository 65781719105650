import { ReactNode } from "react";

interface ISubscriptionPointProps {
  icon: ReactNode;
  title: ReactNode;
  description: ReactNode;
}

export const SubscriptionPoint = ({
  icon,
  title,
  description,
}: ISubscriptionPointProps) => {
  return (
    <div className="pb-10 pb-md-15 pe-15">
      <div className="mb-3">{icon}</div>
      <h3 className="text-black fw-normal">{title}</h3>
      <p className="fs-14">{description}</p>
    </div>
  );
};
