import invoicesSampleScreen from "../../assets/img/sample-screens/invoices.png";
import generateInvoice from "../../assets/img/photos/invoices/generate-invoice.png";
import operationalSimplicity from "../../assets/img/photos/invoices/operational-simplicity.png";
import paymentOptions from "../../assets/img/photos/invoices/payment-providers.png";

// icons
import noteGreenIcon from "../../assets/img/icons/note-green.svg";
import cardsOutlineIcon from "../../assets/img/icons/cards-outline.svg";
import clipboardTickOutlineIcon from "../../assets/img/icons/clipboard-tick-outline.svg";
import globalRefreshOutlineIcon from "../../assets/img/icons/global-refresh-outline.svg";
import statusUpOutlineIcon from "../../assets/img/icons/status-up-outline.svg";
import tickCircleGreenIcon from "../../assets/img/icons/tick-circle-green-outline.svg";

export const images = {
  invoicesSampleScreen,
  generateInvoice,
  operationalSimplicity,
  paymentOptions,

  // icons
  noteGreenIcon,
  cardsOutlineIcon,
  clipboardTickOutlineIcon,
  globalRefreshOutlineIcon,
  statusUpOutlineIcon,
  tickCircleGreenIcon,
};
