import { BulletTitleDescription } from "../../components";
import { appConstants } from "../../constants";

import { images } from "./images";

export const View = () => {
  return (
    <div className="reports-page">
      <section className="wrapper bg-pale-brown main-section pb-30">
        <div className="container pt-7 pt-md-5 pb-8 h-100">
          <div className="row h-100 gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6 align-self-end mb-n5 mb-md-n10"
              // data-cues="slideInDown"
              // data-group="page-title"
              // data-delay="600"
            >
              <h4 className="text-black-50 fs-16">FEATURES</h4>
              <h3 className="display-1 fw-normal mb-12">
                <img
                  src={images.fraudNoteIcon}
                  alt="page icon"
                  className="me-3 hero-icon"
                />
                Fraud &amp; risks
              </h3>
              <p className="lead fs-16 lh-sm mb-7 pe-md-18 pe-lg-0 pe-xxl-15 text-black">
                Keep your revenue secure. SwitchApp prevents fraudulent
                chargebacks, stops card attacks, and exposes fraudsters.
              </p>
              <div>
                <a
                  href={appConstants.appSignInUrl}
                  className="btn btn-md btn-primary me-3 mt-3 rounded"
                >
                  Sign In
                </a>
                <a
                  href={appConstants.appSignUpUrl}
                  className="btn btn-md btn-outline-primary mt-3 rounded"
                >
                  Request Invitation <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* <!-- /column --> */}
            <div
              className="col-lg-6 mb-n18 me-n18"
              // data-cues="slideInDown"
            >
              <div className="position-relative light-gallery-wrapper">
                <figure className="cut-off-container">
                  <img
                    src={images.fraudsAndRisksSampleScreen}
                    alt=""
                    className="hero-image"
                    style={{ width: 1200, maxWidth: "none" }}
                  />
                </figure>
              </div>
              {/* <!-- /div --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-10 mt-md-0">
        <div className="container pt-15 pb-20">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-start">
            <div className="col-lg-5">
              <h3 className="display-4 fw-normal mb-10 pe-xxl-15">
                All payments fully protected
              </h3>
              <p className="fw-normal text-black">
                SwitchApp’s Subscription Billing Engine provides everything you
                need to reach clients all around the world in a single,
                straightforward integration. Customizable billing plans,
                automatic payment administration, and worldwide optimization
                methods that can help you expand your business abroad.
              </p>
            </div>
            {/* <!-- /column --> */}

            <figure className="col-lg-7 me-n10 hover scale">
              <a href="#">
                <img
                  src={images.fullyProtected}
                  alt="integrate icon"
                  className="fully-protected-image"
                />
              </a>
            </figure>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-22 mt-md-0">
        <div className="container pt-15 pb-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 ">
            <div className="col-12">
              <h3 className="display-4 fw-normal mb-3">
                Fraud detection that ensures
                <br className="d-none d-lg-block" /> you get compensated for
                your services
              </h3>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <ul className="nav row justify-content-between">
                <BulletTitleDescription
                  title="Global Payments Fraud Prevention"
                  content={
                    <p>
                      Our All-in-One Payment Platform is powered by the most
                      advanced fraud-fighting technology, allowing you to
                      accelerate global payments while reducing risk.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-5"
                  contentClassName="text-black"
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Built-in Risk Management"
                  content={
                    <p>
                      Low risk, high potential reward. With our comprehensive
                      fraud stack, you can protect your business at every stage
                      of transactions.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-10"
                  contentClassName="text-black"
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Chargebacks handled for you"
                  content={
                    <p>
                      SwitchApp fights chargebacks on your behalf, ensuring you
                      don’t lose money due to incorrect or fraudulent claims.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-5"
                  contentClassName="text-black"
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Keep more of your revenue"
                  content={
                    <p>
                      Genuine customers can make payments while fraudsters are
                      prevented with our anti-fraud tools and expertise.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-10"
                  contentClassName="text-black"
                />
                {/* <!--/column --> */}
              </ul>
              {/* <!--/row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-5 mt-md-0">
        <div className="container py-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 ">
            <div className="col-12 col-lg-6">
              <h3 className="display-4 fw-normal mb-3">
                The most advanced methods <br className="d-none d-lg-block" />{" "}
                of fraud prevention.
              </h3>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <ul className="nav flex-column justify-content-between">
                    <BulletTitleDescription
                      title="Automated fraud detection"
                      content={
                        <p>
                          Our powerful AI technology detects fraudulent
                          transactions precisely without risking your
                          transaction revenues.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title="Advanced chargeback prevention"
                      content={
                        <p>
                          We prevent chargebacks from occurring, keeping your
                          chargeback volumes and rates to a minimum.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title="Your business is our utmost priority"
                      content={
                        <p>
                          We are at the forefront of fraud prevention and use
                          industry-leading fraud prevention methods.
                        </p>
                      }
                    />
                  </ul>
                </div>
                {/* <!--/column --> */}

                <img
                  src={images.fraudSecure}
                  alt="integrate icon"
                  className="col-lg-6"
                />
                {/* <!--/column --> */}
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      {/* bg-gradient-gray */}
      {/* <!-- /section --> */}
    </div>
  );
};
