import paymentsSampleScreen from "../../assets/img/sample-screens/payments.png";
import oneStopPaymentEngine from "../../assets/img/photos/payments/one-stop-payment-engine.png";
import paymentOptions from "../../assets/img/photos/payments/payment-options.png";
import fraudSecure from "../../assets/img/photos/payments/fraud-secure@2x.png";

// icons
import cardPosIcon from "../../assets/img/icons/card-pos.svg";
// import xxxxxxxxxxx Icon from "../../assets/img/icons/xxxxxxxxxxx.svg";

export const images = {
  paymentsSampleScreen,
  oneStopPaymentEngine,
  paymentOptions,
  fraudSecure,

  // icons
  cardPosIcon,
};
