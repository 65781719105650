import flagsNigeria from "../../assets/img/photos/pricing/flags-nigeria.png";

import amexCard from "../../assets/img/photos/payment-links/amex-card-logo@2x.png";
import discoverCard from "../../assets/img/photos/payment-links/discover-card-logo@2x.png";
import mastercard from "../../assets/img/photos/payment-links/mastercard-logo@2x.png";
import visaCard from "../../assets/img/photos/payment-links/visa-logo@2x.png";

// icons
import chevronRightBlueIcon from "../../assets/img/icons/chevron-right-blue.svg";
import tickCircleBlueIcon from "../../assets/img/icons/tick-circle-blue.svg";

export const images = {
  flagsNigeria,
  amexCard,
  discoverCard,
  mastercard,
  visaCard,

  // icons
  chevronRightBlueIcon,
  tickCircleBlueIcon,
};
