import reportsSampleScreen from "../../assets/img/sample-screens/reports.png";
import maximizeValuation from "../../assets/img/photos/reports/chart-in-tablet.png";
import paymentProviderReports from "../../assets/img/photos/reports/payment-provider-reports@2x.png";

// icons
import pieChartIcon from "../../assets/img/icons/pie-chart.svg";
import tickCirclePinkIcon from "../../assets/img/icons/tick-circle-pink.svg";

export const images = {
  reportsSampleScreen,
  maximizeValuation,
  paymentProviderReports,

  // icons
  pieChartIcon,
  tickCirclePinkIcon,
};
