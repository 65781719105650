import { BulletTitleDescription } from "../../components";
import { appConstants } from "../../constants";

import { images } from "./images";

export const View = () => {
  return (
    <div className="ai-for-businesses-page">
      <section className="wrapper bg-aqua main-section pb-30">
        <div className="container pt-7 pt-md-5 pb-8 h-100">
          <div className="row h-100 gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6 align-self-end mb-n5 mb-md-n10"
              // data-cues="slideInDown"
              // data-group="page-title"
              // data-delay="600"
            >
              <h4 className="text-black-50 fs-16">FEATURES</h4>
              <h3 className="display-1 fw-normal mb-12">
                <img
                  src={images.aiForBusinessesIcon}
                  alt="page icon"
                  className="me-3 hero-icon"
                />
                AI for Businesses
              </h3>
              <p className="lead fs-16 lh-sm mb-7 pe-md-18 pe-lg-0 pe-xxl-15 text-black">
                SwitchApp specializes in providing cutting-edge AI solutions
                tailored to meet the unique needs of businesses. Leveraging the
                power of artificial intelligence, we help businesses optimize
                processes, enhance decision-making, and drive innovation.
              </p>
              <div>
                <a
                  href={appConstants.appSignInUrl}
                  className="btn btn-md btn-primary me-3 mt-3 rounded"
                >
                  Sign In
                </a>
                <a
                  href={appConstants.appSignUpUrl}
                  className="btn btn-md btn-outline-primary mt-3 rounded"
                >
                  Request Invitation <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* <!-- /column --> */}
            <div
              className="col-lg-6 mb-n18 me-n18"
              // data-cues="slideInDown"
            >
              <div className="position-relative light-gallery-wrapper mt-0 mt-xl-15">
                <figure className="cut-off-container ai-for-businesses-banner hero-image">
                  {/* <img
                    src={images.aiForBusinessesBanner}
                    alt=""
                    className="hero-image"
                    style={{ width: 1200, height: "650px", maxWidth: "none" }}
                    // style={{ backgroundColor: "#76BBDA" }}
                  /> */}
                </figure>
              </div>
              {/* <!-- /div --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-10 mt-md-0">
        <div className="container pt-20 pb-20">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-start">
            <div className="col-lg-5">
              <h3 className="display-4 fw-normal mb-10 pe-xxl-15">
                Interact naturally with your powerful personal AI assistant
              </h3>
              <p className="fw-normal text-black">
                Experience the future of business management with our
                interactive AI assistant. Empower your business with our
                revolutionary interactive AI assistant that can assist with all
                aspects of business management, from scheduling appointments to
                analyzing financial data.
              </p>
            </div>
            {/* <!-- /column --> */}

            <figure className="col-lg-7 me-n10 hover scale">
              <a href="#">
                <img
                  src={images.aiAssistant}
                  alt="integrate icon"
                  className="interact-naturally-image"
                />
              </a>
            </figure>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-22 mt-md-0">
        <div className="container pt-15 pb-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 ">
            <div className="col-12">
              <h3 className="display-4 fw-normal mb-3">
                Packed with advanced features to transform your business.
              </h3>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <ul className="nav row justify-content-between">
                <BulletTitleDescription
                  title="Natural Language Processing (NLP)"
                  content={
                    <p>
                      Enhance customer interactions and streamline communication
                      processes with NLP-powered chatbots and virtual
                      assistants.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-10"
                  contentClassName="text-black"
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Data Analysis and Predictive Modeling"
                  content={
                    <p>
                      Utilize advanced algorithms to analyze data, identify
                      patterns, and make accurate predictions, enabling informed
                      decision-making.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-5"
                  contentClassName="text-black"
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Image Recognition"
                  content={
                    <p>
                      Automate visual inspections of identity and
                      backing-documents , enhance security measures, and improve
                      product identification with our image recognition
                      solutions.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-5"
                  contentClassName="text-black"
                />
                {/* <!--/column --> */}

                <BulletTitleDescription
                  title="Process Automation"
                  content={
                    <p>
                      Streamline repetitive tasks, reduce manual errors, and
                      increase efficiency across various business processes
                      through intelligent automation.
                    </p>
                  }
                  containerClassName="col-12 col-md-6 col-lg-3"
                  titleClassName="mb-md-10"
                  contentClassName="text-black"
                />
                {/* <!--/column --> */}
              </ul>
              {/* <!--/row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-5 mt-md-0">
        <div className="container py-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 ">
            <div className="col-12 col-lg-6">
              <h3 className="display-4 fw-normal mb-3">
                Step into the future of business management
              </h3>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <ul className="nav flex-column justify-content-between">
                    <BulletTitleDescription
                      title="Optimize processes"
                      content={
                        <p>
                          Our powerful AI technology will inform you of steps
                          that can be taken to improve your current workflow. It
                          will also assist you in its implementation and
                          monitoring.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title="Enhance decision-making"
                      content={
                        <p>
                          AI can provide instantaneous insight into data that
                          would require teams of experts. This enables small
                          businesses to grow even with much less budgets than
                          the incumbents.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title="Drive innovation"
                      content={
                        <p>
                          AI errodes all boundaries to ideas and their
                          execution, freeing your mind to do its best work.
                        </p>
                      }
                    />
                  </ul>
                </div>
                {/* <!--/column --> */}

                <img
                  src={images.aiAutomation}
                  alt="integrate icon"
                  className="col-lg-6"
                />
                {/* <!--/column --> */}
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      {/* bg-gradient-gray */}
      {/* <!-- /section --> */}
    </div>
  );
};
