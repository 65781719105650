import paymentLinksSampleScreen from "../../assets/img/sample-screens/payment-links.png";
import noCodeRequired from "../../assets/img/photos/payment-links/no-code-required.png";
import createLinkCodeSnippet from "../../assets/img/photos/payment-links/create-link-code-snippet.svg";
import amexCard from "../../assets/img/photos/payment-links/amex-card-logo@2x.png";
import discoverCard from "../../assets/img/photos/payment-links/discover-card-logo@2x.png";
import mastercard from "../../assets/img/photos/payment-links/mastercard-logo@2x.png";
import visaCard from "../../assets/img/photos/payment-links/visa-logo@2x.png";
// import xxxxxxxx from "../../assets/img/photos/payment-links/xxxxxxxx.png";

// icons
import linkIcon from "../../assets/img/icons/link.svg";
import tickCircleCyanIcon from "../../assets/img/icons/tick-circle-cyan.svg";
import backgroundGraphicSvg from "../../assets/img/icons/background-graphic.svg";
import orderedList1Icon from "../../assets/img/icons/ol-1.svg";
import orderedList2Icon from "../../assets/img/icons/ol-2.svg";
import orderedList3Icon from "../../assets/img/icons/ol-3.svg";
import orderedList4Icon from "../../assets/img/icons/ol-4.svg";

export const images = {
  paymentLinksSampleScreen,
  noCodeRequired,
  amexCard,
  discoverCard,
  mastercard,
  visaCard,

  // icons
  linkIcon,
  tickCircleCyanIcon,
  backgroundGraphicSvg,
  createLinkCodeSnippet,
  orderedList1Icon,
  orderedList2Icon,
  orderedList3Icon,
  orderedList4Icon,
};
