// icons
import footerLogo from "../../assets/img/icons/switchapp-logo-transparent.svg";
import shopIcon from "../../assets/img/icons/shop-outline.svg";
import terminalIcon from "../../assets/img/icons/code-console-outline.svg";

export const images = {
  footerLogo,
  shopIcon,
  terminalIcon,
};
