import { appConstants } from "../../constants";
import { useTheme } from "../../hooks";
import { GradientCard } from "../GradientCard";
import { CopySuccessThemedIcon } from "./CopySuccessThemedIcon";

import { images } from "./images";
import { StackThemedIcon } from "./StackThemedIcon";

export const FooterApiSection = () => {
  const { pageTheme } = useTheme();
  return (
    <section className="wrapper bg-gradient-gray angled upper-end">
      <div className="container pt-10 pt-md-10 pb-10 pb-md-10">
        <div className="row">
          <div className="col-lg-10">
            <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
              FOR DEVELOPERS
            </h2>
            <h3 className="display-4 fw-normal mb-3">
              Modern, powerful easy-to-use APIs
            </h3>
            <p className="mb-9">
              Integrate open source code samples, rapidly build production-ready
              integrations with well-documented APIs just for you, that lets you
              build financial products of different levels serving thousands of
              customers.
            </p>
          </div>
          {/* <!-- /column --> */}
        </div>
        {/* <!-- /.row --> */}

        <div className="row gx-md-8 gy-8 mb-14 mb-md-18">
          <div className="col-md-6">
            <GradientCard
              icon={<CopySuccessThemedIcon />}
              title={"Integrate with SwitchApp"}
              linkUrl={appConstants.apiDocumentationUrl}
              linkText={"Read documentation"}
              className={pageTheme}
            >
              Production-ready, easy-to-follow well documented APIs for
              integrating payments on your websites and mobile app.
            </GradientCard>
          </div>
          {/* <!-- /column --> */}

          <div className="col-md-6">
            <GradientCard
              icon={<StackThemedIcon />}
              linkUrl={appConstants.librariesUrl}
              linkText={"View libraries"}
              title={"Explore our libraries"}
              className={pageTheme}
            >
              An array of tools to power the future of payments. We offer
              libraries in everything from React and PHP to .NET
            </GradientCard>
          </div>
          {/* <!-- /column --> */}
        </div>
        {/* <!-- /.row --> */}
      </div>
      {/* <!-- /.container --> */}
    </section>
  );
};
