import { ISwCardProps } from "./SwCard";

export const View = (props: ISwCardProps) => {
  return (
    <div className={`card ${props.className}`}>
      {props.title ? (
        <div className="card-header">
          <h2 className="text-white">{props.title}</h2>
        </div>
      ) : null}
      {/* <!--/.card-header --> */}

      <div className="card-body">{props.children}</div>
      {/* <!--/.card-body --> */}
    </div>
  );
};
