export const termsAndConditionsHtml: string = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Century Gothic";
	panose-1:2 11 5 2 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:0in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:.5in;
	margin-bottom:.0001pt;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:8.0pt;
	margin-left:.5in;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
-->
</style>

</head>

<body lang=EN-US>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><a name="_Hlk115360829"><span
style='font-family:"Century Gothic",sans-serif;color:#222222'>&nbsp;</span></a></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>The use of the SwitchApp website (hereinafter referred to as www.switchappgo.com)
and tools are governed by the following terms and conditions as applicable to SwitchApp
Tech Solutions Limited. If you transact on the website you are therefore,
subject to the policies that are applicable to website transactions. </span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>By the mere use of the website you are contracting with SwitchApp
Tech Solutions Limited, a Company incorporated under the Companies and Allied
Matters Act, 2020. Please </span><u><span style='font-family:"Century Gothic",sans-serif'><a href="https://www.switchappgo.com/about" target="blank" link="noreferer">Click here</a>
</span></u><span style='font-family:"Century Gothic",sans-serif'> <span
style='color:#222222'>to read more information about us. </span></span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>When you use our services, including consultations and
enquiries, customer reviews, you shall be subject to the rules, guidelines and
policies applicable to such services.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>When you make use of our services (WhatsApp, Facebook,
Instagram, Website, or Others), these terms apply. These terms are important
and would give you helpful information. You can also send an email to our
Customer Care Team – on (</span><span style='font-family:"Century Gothic",sans-serif;
color:black'><a href="mailto:support@switchappgo.com" target="blank" link="noreferer">support@switchappgo.com</a></span><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>) we are a customer care-driven company and would love to give
you the best experience.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>By clicking on our website, you consent that you have or have
obtained the necessary authority to agree to these terms and conditions and
these terms constitute binding obligations.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'>User Eligibility</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><u><span style='font-family:"Century Gothic",sans-serif;
color:#222222'><span style='text-decoration:none'>&nbsp;</span></span></u></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>The use of SwitchApp is only available to an adult not less than
18 years old. If you are a minor under the age of 18 years you shall not
register or sign up on the website. As a minor if you wish to transact on the
website, such use or transaction must be carried out by a guardian or parents
who are registered on the website.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><u><span style='font-family:"Century Gothic",sans-serif;
color:#222222'><span style='text-decoration:none'>&nbsp;</span></span></u></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'>Communications</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>When you use the website or send emails or other relevant data,
information or communication, you agree and understand that you are
communicating with SwitchApp through electronic records periodically and as
when required.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'>User Ethics</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><u><span style='font-family:"Century Gothic",sans-serif;
color:#222222'><span style='text-decoration:none'>&nbsp;</span></span></u></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>Do not misuse or tamper with our websites, apps or other
services ("Websites") (e.g. hack, introduce viruses, trojans, worms, logic
bombs or other technologically harmful material or carry out denial of service
attacks etc) or otherwise. Neither should you tamper with our technology
platforms or functionality or steal ours or our customers' data. Doing any of
these things is a criminal offence, and as well also get in the way of us
giving our loyal customers the best service. We will report any such breach or
activity (and all information about the people carrying it out) to the relevant
regulatory authorities.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>We recommend you use virus protection software when using any
website, including ours. Although we have a dedicated team who work hard to
stop people from causing chaos with our Website, we can't guarantee that it
will always be secure from bugs or viruses. You are also not allowed to use
automated systems or software to extract data from our Website (AKA 'screen
scraping').</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>Please take note: you agree to indemnify, defend and hold
harmless SwitchApp, its directors, officers, employees, consultants, agents,
and affiliates, from any and all third-party claims, liability, damages and/or
costs (including, but not limited to, legal fees) arising from your use or
misuse of this Website or your breach of the Terms and Conditions.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-size:12.0pt;
font-family:"Century Gothic",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-size:12.0pt;
font-family:"Century Gothic",sans-serif;color:#222222'>Intellectual Property
Rights</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-size:12.0pt;
font-family:"Century Gothic",sans-serif;color:#222222'><span style='text-decoration:
 none'>&nbsp;</span></span></u></b></p>

<p class=MsoNormalCxSpMiddle style='margin-left:.75in;text-align:justify;
text-indent:-.25in'><span style='font-family:"Century Gothic",sans-serif;
color:black'>a.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Century Gothic",sans-serif;color:black'>All
trademarks, copyright, database rights, patent, and other intellectual property
rights of any nature in the SwitchApp website together with the underlying
software code, its affiliated content (which may be accessed outside SwitchApp-controlled
webpages), and any and all rights in, or derived from the App are proprietary
and owned either directly by SwitchApp licensors and is protected by applicable
intellectual property and other laws. </span></p>

<p class=MsoNormalCxSpMiddle style='margin-left:.75in;text-align:justify;
text-indent:-.25in'><span style='font-family:"Century Gothic",sans-serif;
color:black'>b.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Century Gothic",sans-serif;color:black'>You
agree that you will not use such proprietary information or materials in any
way whatsoever except for use of the website in compliance with these Terms and
Agreement. No portion of the website may be reproduced in any form or by any
means, except as expressly permitted in these Terms. You agree not to modify,
rent, lease, loan, sell, distribute, or create derivative works based on the website
in any manner, and you shall not exploit the website in any unauthorized way
whatsoever, including, but not limited to, by trespass or burdening network
capacity.</span></p>

<p class=MsoNormalCxSpMiddle style='margin-left:.75in;text-align:justify;
text-indent:-.25in'><span style='font-family:"Century Gothic",sans-serif;
color:black'>c.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Century Gothic",sans-serif;color:black'>SwitchApp
hereby grants you a non-exclusive, non-transferable, non-sublicensable,
worldwide, and perpetual revocable license to use for your lawful use in
accordance with these Terms and the Agreement on your Device. SwitchApp retains
all rights, title, and interest in the SwitchApp Platform, and its other
intellectual property. This license starts on the date that you accept these
Terms and install or otherwise use the website. The license ends on the earlier
date of your disposal of the website.</span></p>

<p class=MsoNormalCxSpMiddle style='margin-left:.75in;text-align:justify;
text-indent:-.25in'><span style='font-family:"Century Gothic",sans-serif;
color:black'>d.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Century Gothic",sans-serif;color:black'>The
website may request from you any feedback, including but not limited to product
or retailer information, or images of receipts or products
(&nbsp;"Feedback"&nbsp;). to use, exploit, or modify the Feedback in any way
and for any purpose without payment to you.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-size:12.0pt;
font-family:"Century Gothic",sans-serif;color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>Third Party registered and unregistered trademarks or service
marks on our website are the property of their respective owners and we do not
endorse and are not affiliated with any of the holders of any such marks and as
such we cannot grant any license to the use or otherwise of third-party
intellectual property. </span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'><span style='text-decoration:none'>&nbsp;</span></span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'>Legal&nbsp;</span></u></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><b><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></b></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>We have the right to amend, remove or vary our services and/or
any part of the&nbsp;Website&nbsp;(including our Ts&amp;Cs) at any time.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>These terms and our dealings with you are subject to the
Nigerian law and the exclusive jurisdiction of the Nigerian courts.</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;text-align:
justify;line-height:normal;background:white'><span style='font-family:"Century Gothic",sans-serif;
color:#222222'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:#222222'>Data Privacy </span></u></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Century Gothic",sans-serif'>We
view protection of your privacy as a very important principle. We understand
clearly that you and your personal information are one of our most important
assets. We store your information on computers that may be protected by
physical and reasonable technological security measures in accordance with the
Information Security Technology Act and rules hereunder. Our current privacy
policy is available at <a href="https://www.switchappgo.com/privacy" target="blank" link="noreferer"> <b> <i>https://www.switchappgo.com/privacy</i></b></a> and information
submitted may be made available to SwitchApp support staff.</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Century Gothic",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:black'>Modifications</span></u></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Century Gothic",sans-serif;
color:black'>SwitchApp reserves the right, at its sole discretion, to change,
suspend, or discontinue the Platform (including without limitation, the
availability of any feature or content) at any time.&nbsp;SwitchApp may, at its
sole discretion, remove certain services from the SwitchApp Platform if it is determined
that such services could subject to undue regulatory risk and safety risk, or any
other liability.&nbsp;SwitchApp also may revise these Terms from time to time.
By continuing to access or use the Services after those revisions become
effective, you agree to be bound by the revised Terms.</span><b><span
style='font-size:12.0pt;line-height:107%;font-family:"Times New Roman",serif;
color:black'> </span></b></p>

<p class=MsoNormal style='text-align:justify'><b><span style='font-size:12.0pt;
line-height:107%;font-family:"Times New Roman",serif;color:black'>&nbsp;</span></b></p>

<p class=MsoNormal style='text-align:justify'><b><u><span style='font-family:
"Century Gothic",sans-serif;color:black'>Representations and Warranties;
Additional Responsibilities; Warranty Disclaimer.</span></u></b></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Century Gothic",sans-serif;
color:black'>Each user represents and warrants that they have the full right,
power, and authority to enter into and perform these obligations set hereunder
Agreement without breach. As a user, you represent and warrant that you will
comply with all applicable laws and regulations in the performance of this
Agreement, including without limitation (i) all applicable data protection and
privacy laws, and (ii) all applicable laws related to third party intellectual
property and other proprietary rights. </span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Century Gothic",sans-serif;
color:black'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Century Gothic",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal style='text-align:justify'><span style='font-family:"Century Gothic",sans-serif'>&nbsp;</span></p>

<p class=MsoNormal><span style='font-family:"Century Gothic",sans-serif'>&nbsp;</span></p>

</div>

</body>

</html>
`;
