import { Carousel } from "../../components";
import { images } from "./images";
import { PaymentProviderCarouselItem } from "./PaymentProviderCarouselItem";

export const PaymentProviderCarousel = () => {
  return (
    <>
      <div className="card col-6 col-md-3 me-3 mb-3">
        <PaymentProviderCarouselItem
          providerName="flutterwave"
          providerIcon={
            <img
              src={images.flutterwaveLogo}
              className="payment-provider-carousel-logo"
              alt="payment provider logo"
            />
          }
          status="connected"
          info={<p>EMEA &amp; USA</p>}
        />
      </div>

      <div className="card col-6 col-md-3 me-3">
        <PaymentProviderCarouselItem
          providerName="paystack"
          providerIcon={
            <img
              src={images.paystackLogo}
              className="payment-provider-carousel-logo"
              alt="payment provider logo"
            />
          }
          status="connected"
          info={<p>EMEA regions</p>}
        />
      </div>

      <div className="card col-6 col-md-3 me-3">
        <PaymentProviderCarouselItem
          providerName="stripe"
          providerIcon={
            <img
              src={images.stripeLogo}
              className="payment-provider-carousel-logo"
              alt="payment provider logo"
            />
          }
          status="pending"
          info={<p>Global</p>}
        />
      </div>

      <div className="card col-6 col-md-3 me-3">
        <PaymentProviderCarouselItem
          providerName="squad"
          providerIcon={
            <img
              src={images.squadLogo}
              className="payment-provider-carousel-logo"
              alt="payment provider logo"
            />
          }
          status="connected"
          info={<p>EMEA regions</p>}
        />
      </div>

      {/* <div className="col-6 col-md-3">
            xxxxxxxx
        </div> */}
    </>
  );

  //   return (
  //     <Carousel
  //       items={[
  //         <PaymentProviderCarouselItem
  //           providerName="flutterwave"
  //           providerIcon={
  //             <img
  //               src={images.flutterwaveLogo}
  //               className=""
  //               alt="payment provider logo"
  //             />
  //           }
  //           status="connected"
  //           info={<p>available in EMEA &amp; USA</p>}
  //         />,
  //         <PaymentProviderCarouselItem
  //           providerName="paystack"
  //           providerIcon={
  //             <img
  //               src={images.paystackLogo}
  //               className=""
  //               alt="payment provider logo"
  //             />
  //           }
  //           status="connected"
  //           info={<p>available in EMEA</p>}
  //         />,
  //         <PaymentProviderCarouselItem
  //           providerName="stripe"
  //           providerIcon={
  //             <img
  //               src={images.stripeLogo}
  //               className=""
  //               alt="payment provider logo"
  //             />
  //           }
  //           status="pending"
  //           info={<p>available in 30+ countries</p>}
  //         />,
  //       ]}
  //     />
  //   );
};
