import { Link } from "react-router-dom";
import { Badge, BulletTitleDescription, SwCard } from "../../components";
import { appConstants } from "../../constants";
import { CheckListItem } from "./CheckListItem";

import { images } from "./images";

export const View = () => {
  return (
    <div className="payment-links-page">
      <section className="wrapper bg-soft-aqua main-section pb-30">
        <div className="container pt-7 pt-md-5 pb-8 h-100">
          <div className="row h-100 gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6 align-self-end mb-n5 mb-md-n10"
              // data-cues="slideInDown"
              // data-group="page-title"
              // data-delay="600"
            >
              <h4 className="text-black-50 fs-16">FEATURES</h4>
              <h3 className="display-1 fw-normal mb-12">
                <img
                  src={images.linkIcon}
                  alt="page icon"
                  className="me-3 hero-icon"
                />
                Payment links
              </h3>
              <p className="lead fs-16 lh-sm mb-7 pe-md-18 pe-lg-0 pe-xxl-15 text-black">
                Online sales without a website, no code is required; simply
                create a payment link in just a few click and share it with your
                customers.
              </p>
              <div>
                <a
                  href={appConstants.appSignInUrl}
                  className="btn btn-md btn-primary me-3 mt-3 rounded"
                >
                  Sign In
                </a>
                <a
                  href={appConstants.appSignUpUrl}
                  className="btn btn-md btn-outline-primary mt-3 rounded"
                >
                  Request Invitation <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* <!-- /column --> */}
            <div
              className="col-lg-6 mb-n18 me-n18"
              // data-cues="slideInDown"
            >
              <div className="position-relative light-gallery-wrapper">
                <figure className="cut-off-container">
                  <img
                    src={images.paymentLinksSampleScreen}
                    alt=""
                    className="hero-image"
                    style={{ width: 1200, maxWidth: "none" }}
                  />
                </figure>
              </div>
              {/* <!-- /div --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-22 mt-md-0">
        <div className="container pt-20 pb-md-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 justify-content-center">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12">
                  {/* <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
                  Our payment experience
                </h2> */}
                  <h3 className="display-4 fw-normal mb-10">
                    With no coding required, accept payments in minutes.
                  </h3>
                </div>
                {/* <!--/column --> */}
                <div className="col-lg-10">
                  <ul className="nav flex-column justify-content-between">
                    <BulletTitleDescription
                      title={
                        <div className="">
                          <img
                            src={images.orderedList1Icon}
                            alt="checklist icon"
                            className="checklist-icon me-3"
                          />
                          Create a link
                        </div>
                      }
                      titleClassName="no-left-border"
                      content={
                        <p className="fw-normal text-black">
                          Payment Links can be used to start a subscription,
                          sell products or services, or collect donations. Add
                          your logo and colors to match the look and feel of
                          your brand.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title={
                        <div className="">
                          <img
                            src={images.orderedList2Icon}
                            alt="checklist icon"
                            className="checklist-icon me-3"
                          />
                          Add product details
                        </div>
                      }
                      titleClassName="no-left-border"
                      content={
                        <p className="fw-normal text-black">
                          Add important details about your product offerings -
                          images, prices, quantities and more.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title={
                        <div className="">
                          <img
                            src={images.orderedList3Icon}
                            alt="checklist icon"
                            className="checklist-icon me-3"
                          />
                          Get paid
                        </div>
                      }
                      titleClassName="no-left-border"
                      content={
                        <p className="fw-normal text-black">
                          Voila! You’re all set up to start receiving payments
                          from anywhere in the world instantly.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title={
                        <div className="">
                          <img
                            src={images.orderedList4Icon}
                            alt="checklist icon"
                            className="checklist-icon me-3"
                          />
                          Share the link
                        </div>
                      }
                      titleClassName="no-left-border"
                      content={
                        <p className="fw-normal text-black">
                          Copy, paste or embed the link anywhere. The same
                          payment link can be shared with different customers
                          and used across multiple channels.
                        </p>
                      }
                    />
                  </ul>
                </div>
                {/* <!--/column --> */}
              </div>
            </div>
            {/* <!--/column --> */}

            <img
              src={images.noCodeRequired}
              alt="integrate icon"
              className="col-lg-6 one-stop-payment-image"
            />
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-soft-blue mt-10 mt-md-0 mt-10 mt-md-0">
        <div className="container pt-15 pb-20 py-md-20">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2">
            <img
              src={images.createLinkCodeSnippet}
              alt="subscription details"
              className="col-lg-7 mt-lg-2"
            />
            {/* <!-- /column --> */}

            <div className="col-lg-5">
              <h3 className="display-4 fw-normal mb-5 pe-xxl-15">
                Seamless integration built to save dev time.
              </h3>
              <p className="fw-normal text-black">
                Our Payment API makes it easy to offer an ideal online checkout
                experience <br className="d-none d-lg-block" />
                and payment flow, giving you the tools you need to expand to new
                markets. <br />
                Bottom line? You can get started quickly and easily while we
                take care of all the heavy lifting.
              </p>

              <div className="col-lg-10">
                <h4 className="fs-20 mb-3">Each invoice includes</h4>
              </div>
              {/* <!-- /column --> */}

              <div className="col-12 ps-3">
                <div className="row">
                  <CheckListItem className="col-12 col-md-6">
                    Product details
                  </CheckListItem>
                  {/* <!--/column --> */}

                  <CheckListItem className="col-12 col-md-6">
                    Test Environment
                  </CheckListItem>
                  {/* <!--/column --> */}

                  <CheckListItem className="col-12 col-md-6">
                    Customer information
                  </CheckListItem>
                  {/* <!--/column --> */}

                  <CheckListItem className="col-12 col-md-6">
                    Different Payment Method
                  </CheckListItem>
                  {/* <!--/column --> */}

                  <CheckListItem className="col-12 col-md-6">
                    Memo
                  </CheckListItem>
                  {/* <!--/column --> */}
                </div>
                {/* <!--/row --> */}
              </div>
              {/* <!--/column --> */}

              <div className="col-lg-12">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-md btn-outline-primary rounded mt-3"
                  href={`${appConstants.apiDocumentationUrl}/payment-links/payment-links`}
                >
                  Explore the docs
                </a>
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}

        {/* <div className="w-100 h-100 bg-blurred-ellipse position-absolute" /> */}
        {/* blurred background */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light angled upper-end  mt-n10 mt-md-n10 mt-lg-n12">
        <div className="container py-10 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2">
            <div className="col-lg-4">
              <h3 className="display-4 fw-normal mb-10 pe-xxl-15">
                No monthly fees.
              </h3>
              <p className="fw-normal text-black">
                Our payment Links let you accept credit cards, debit cards,
                mobile wallets, and more, its included in SwitchApp’s integrated
                pricing.
              </p>
            </div>
            {/* <!-- /column --> */}

            <div className="col-lg-8">
              <Badge kind="aqua" className="me-5 mb-5 fs-16 py-3 fw-normal">
                Accept major credit and debit cards
              </Badge>

              <Badge kind="aqua" className="me-5 mb-5 fs-16 py-3 fw-normal">
                Accept tips
              </Badge>

              <Badge kind="aqua" className="me-5 mb-5 fs-16 py-3 fw-normal">
                Sell items, services, or classes
              </Badge>

              <Badge
                kind="aqua"
                className="me-5 mb-5 fs-16 py-3 fw-normal text-start"
              >
                Accept installment payments <br className="d-block d-md-none" />{" "}
                with Afterpay
              </Badge>

              <Badge kind="aqua" className="me-5 mb-5 fs-16 py-3 fw-normal">
                Accept one-time or recurring donations
              </Badge>

              <Badge
                kind="aqua"
                className="me-5 mb-5 fs-16 py-3 fw-normal text-start"
              >
                Printable QR Codes for unlimited{" "}
                <br className="d-block d-md-none" /> products and service
              </Badge>

              <Badge kind="aqua" className="me-5 mb-5 fs-16 py-3 fw-normal">
                Accept Apple Pay, Google Pay &amp; more
              </Badge>

              <Badge
                kind="aqua"
                className="me-5 mb-5 fs-16 py-3 fw-normal text-start"
              >
                No special app needed to pay{" "}
                <br className="d-block d-md-none" /> or take payments
              </Badge>
            </div>
            {/* <!-- /column --> */}

            <div className="col-12">
              <div className="row">
                <div className="col-md-6 mb-5">
                  <SwCard className="bg-soft-aqua">
                    <h2 className="mb-3 display-6">Local Transactions</h2>
                    <div className="mb-5">
                      <img
                        src={images.visaCard}
                        alt="page icon"
                        className="mb-3 bank-card-icon"
                      />
                      <img
                        src={images.mastercard}
                        alt="page icon"
                        className="mb-3 ms-3 bank-card-icon"
                      />
                    </div>

                    <h2 className="mb-3 display-6">
                      <span className="display-3 text-blue mb-3 me-3">
                        1.4%
                      </span>
                      <span className="fw-normal">per transaction</span>
                    </h2>
                  </SwCard>
                </div>

                <div className="col-md-6 mb-5">
                  <SwCard className="bg-soft-aqua">
                    <h2 className="mb-3 display-6">
                      International Transactions
                    </h2>
                    <div className="mb-5">
                      <img
                        src={images.visaCard}
                        alt="page icon"
                        className="mb-3 bank-card-icon"
                      />
                      <img
                        src={images.mastercard}
                        alt="page icon"
                        className="mb-3 ms-3 bank-card-icon"
                      />
                      <img
                        src={images.discoverCard}
                        alt="page icon"
                        className="mb-3 ms-3 bank-card-icon"
                      />
                      <img
                        src={images.amexCard}
                        alt="page icon"
                        className="mb-3 ms-3 bank-card-icon"
                      />
                    </div>

                    <h2 className="mb-3 display-6">
                      <span className="display-3 text-blue mb-3 me-3">
                        3.8%
                      </span>
                      <span className="fw-normal">per transaction</span>
                    </h2>
                  </SwCard>
                </div>
              </div>
            </div>
            {/* <!-- /column --> */}

            <div className="col-lg-12 text-center">
              <Link
                className="btn btn-md btn-outline-primary rounded mt-3"
                to="/pricing"
              >
                View pricing for other countries
              </Link>
            </div>
            {/* <!--/column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      {/* bg-gradient-gray */}
      {/* <!-- /section --> */}
    </div>
  );
};
