// import { aboutUsHtml } from "./ABOUT";
import { aboutUsHtml } from "./ABOUT_NEW";

export const View = () => {
  return (
    <div className="about-page">
      <section className="wrapper mt-10 mt-md-0">
        <div className="container pb-15 pt-10">
          <div className="row text-center">
            <div className="col-lg-12">
              <h3 className="fw-normal mb-10">ABOUT SWITCHAPP</h3>
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}

          <div className="row gx-md-8 gy-8 justify-content-center">
            <div className="col-md-10">
              <div dangerouslySetInnerHTML={{ __html: aboutUsHtml }} />
            </div>
            {/* <!--/column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}
    </div>
  );
};
