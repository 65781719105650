import { ReactNode } from "react";
import { Carousel } from "../../components";
import { images } from "./images";

interface IPaymentProviderCarouselItemProps {
  providerName: string;
  providerIcon: ReactNode;
  info: ReactNode;
  status: "connected" | "pending";
  className?: string;
}

export const PaymentProviderCarouselItem = (
  props: IPaymentProviderCarouselItemProps
) => {
  return (
    <div
      className={`payment-provider-item px-5 pt-3 position-relative ${props.className}`}
    >
      <div className="mb-3">{props.providerIcon}</div>
      <div className="text-blue mb-0">{props.info}</div>
      <div className="align-right text-end">
        {props.status}
        <div
          className={`status-indicator status-indicator-${props.status}`}
        ></div>
      </div>
      {/* <p className="position-absolute align-right text-end">{props.status}</p> */}
    </div>
  );
};
