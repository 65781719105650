import { BulletTitleDescription } from "../../components";
import { appConstants } from "../../constants";

import { images } from "./images";

export const View = () => {
  return (
    <div className="payments-page">
      <section className="wrapper bg-pale-purple main-section pb-30">
        <div className="container pt-7 pt-md-5 pb-8 h-100">
          <div className="row h-100 gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6 align-self-end mb-n5 mb-md-n10"
              // data-cues="slideInDown"
              // data-group="page-title"
              // data-delay="600"
            >
              <h4 className="text-black-50 fs-16">FEATURES</h4>
              <h3 className="display-1 fw-normal mb-12">
                <img
                  src={images.cardPosIcon}
                  alt="page icon"
                  className="me-3 hero-icon"
                />
                Payments
              </h3>
              <p className="lead fs-16 lh-sm mb-7 pe-md-18 pe-lg-0 pe-xxl-15 text-black">
                SwitchApp’s powerful APIs and software solutions designed to
                help you capture more revenue allow you to accept payments and
                move money globally.
              </p>
              <div>
                <a
                  href={appConstants.appSignInUrl}
                  className="btn btn-md btn-primary me-3 mt-3 rounded"
                >
                  Sign In
                </a>
                <a
                  href={appConstants.appSignUpUrl}
                  className="btn btn-md btn-outline-primary mt-3 rounded"
                >
                  Request Invitation <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
            {/* <!-- /column --> */}
            <div
              className="col-lg-6 mb-n20 me-n18"
              // data-cues="slideInDown"
            >
              <div className="position-relative light-gallery-wrapper">
                <figure className="cut-off-container">
                  <img
                    src={images.paymentsSampleScreen}
                    alt=""
                    className="hero-image"
                    style={{ width: 1200, maxWidth: "none" }}
                  />
                </figure>
              </div>
              {/* <!-- /div --> */}
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-22 mt-lg-0">
        <div className="container pt-22 pb-md-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 justify-content-center">
            <div className="col-12 text-center">
              {/* <h2 className="fs-16 text-uppercase text-line text-primary mb-3">
                  Our payment experience
                </h2> */}
              <h3 className="display-4 fw-normal mb-3">
                Your one-stop payment engine for your business
              </h3>
            </div>
            {/* <!--/column --> */}

            <img
              src={images.oneStopPaymentEngine}
              alt="integrate icon"
              className="col-lg-6 one-stop-payment-image"
            />
            {/* <!--/column --> */}

            <div className="col-lg-6">
              <ul className="nav flex-column justify-content-between">
                <BulletTitleDescription
                  title="Keep more of what you make"
                  content={
                    <p>
                      Save money on payment processing with simple, transparent
                      pricing, without weird or hidden fees or lock-in
                      contracts, ever.
                    </p>
                  }
                />

                <BulletTitleDescription
                  title="Growth ready"
                  content={
                    <p>
                      Instant access to the payments system you need, so you
                      don’t slow your growth by building your own payments
                      stack.
                    </p>
                  }
                />

                <BulletTitleDescription
                  title="Scale globally"
                  content={
                    <p>
                      SwitchApp makes it easy to launch new markets and add your
                      customers’ preferred way of paying to increase conversion
                      abroad.
                    </p>
                  }
                />

                <BulletTitleDescription
                  title="Help customers pay"
                  content={
                    <p>
                      Choose from a range of ready-to-go currencies and payment
                      methods, from local and international payment platforms.
                    </p>
                  }
                />
              </ul>
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-soft-purple mt-10 mt-md-0">
        <div className="container py-10 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2 align-items-end">
            <div className="col-lg-6">
              <h3 className="display-4 fw-normal mb-10 pe-xxl-15">
                Diverse Payment Options for Worldwide Growth
              </h3>
              <p className="fw-normal text-black">
                You can reassure your international customers by providing
                localized payment experiences using their preferred payment
                methods, while having access to a variety of payment providers
                and currencies. As you expand into new markets, our platform
                makes it simple to add or modify payment options so you can meet
                the preferences of your customers wherever and however they
                prefer to pay.
              </p>
            </div>
            {/* <!-- /column --> */}

            <img
              src={images.paymentOptions}
              alt="payment options"
              className="col-lg-6 mt-lg-2"
            />
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-light mt-5 mt-md-0">
        <div className="container py-10 position-relative">
          <div className="row gy-6 mb-0 mb-md-5 ">
            <div className="col-12 col-lg-6">
              <h3 className="display-4 fw-normal mb-3">
                The most advanced methods <br className="d-none d-lg-block" />{" "}
                of fraud prevention.
              </h3>
            </div>
            {/* <!--/column --> */}

            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <ul className="nav flex-column justify-content-between">
                    <BulletTitleDescription
                      title="Automated fraud detection"
                      content={
                        <p>
                          Our powerful AI technology detects fraudulent
                          transactions precisely without risking your
                          transaction revenues.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title="Advanced chargeback prevention"
                      content={
                        <p>
                          We prevent chargebacks from occurring, keeping your
                          chargeback volumes and rates to a minimum.
                        </p>
                      }
                    />

                    <BulletTitleDescription
                      title="Your business is our utmost priority"
                      content={
                        <p>
                          We are at the forefront of fraud prevention and use
                          industry-leading fraud prevention methods.
                        </p>
                      }
                    />
                  </ul>
                </div>
                {/* <!--/column --> */}

                <img
                  src={images.fraudSecure}
                  alt="integrate icon"
                  className="col-lg-6"
                />
                {/* <!--/column --> */}
              </div>
              {/* <!--/column --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* row */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      {/* bg-gradient-gray */}
      {/* <!-- /section --> */}
    </div>
  );
};
