import { Link } from "react-router-dom";
import {
  Accordion,
  Badge,
  BulletTitleDescription,
  SwCard,
} from "../../components";
import { appConstants } from "../../constants";
import { CheckListItem } from "./CheckListItem";

import { images } from "./images";

export const View = () => {
  return (
    <div className="payment-links-page">
      <section className="wrapper bg-light angled upper-end  mt-n10 mt-md-n10 mt-lg-n12">
        <div className="container py-15 py-md-20">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2">
            <div className="col-12 text-center">
              <h3 className="text-primary display-3 fw-normal mb-5 pe-xxl-15">
                The Complete Payment Platform pricing
              </h3>
              <p className="fs-18 fw-normal">
                We provide a simple and transparent pricing model for merchants
                and
                <br className="d-none d-lg-block" /> businesses, with pricing
                designed for businesses of all sizes.
              </p>
            </div>
            {/* <!-- /column --> */}

            <div className="col-12 text-md-center mb-10">
              <div className="vertical-divider bg-primary d-inline-block d-md-none" />
              <span className="fs-20 fw-normal text-black">No setup fees</span>
              {/* group */}
              <br className="d-block d-md-none" />
              <div className="vertical-divider bg-primary" />
              <span className="fs-20 fw-normal text-black">
                No cancellation fees
              </span>{" "}
              {/* group */}
              <br className="d-block d-md-none" />
              <div className="vertical-divider bg-primary" />
              <span className="fs-20 fw-normal text-black">
                No additional fees for eWallets
              </span>
              {/* group */}
            </div>
            {/* <!-- /column --> */}

            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-md-10 mb-8">
                  <SwCard className="bg-soft-aqua px-md-10 px-lg-12 py-md-8 py-lg-10">
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="display-6 mb-8">Local Transactions</h2>
                        <div className="mb-5">
                          <img
                            src={images.visaCard}
                            alt="page icon"
                            className="mb-3 bank-card-icon"
                          />
                          <img
                            src={images.mastercard}
                            alt="page icon"
                            className="mb-3 ms-3 bank-card-icon"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <h2 className="mb-3 display-6">
                          <span className="display-1 fs-65 text-blue mb-3 me-3">
                            1.5%
                          </span>
                          <span className="fs-20 fw-normal">
                            per transaction
                          </span>
                        </h2>

                        <div>
                          <img
                            src={images.chevronRightBlueIcon}
                            alt="page icon"
                            className="me-3"
                          />
                          <span className="fs-18 fw-thin text-black">
                            Local — Cards, Wallets & Mobile Money
                          </span>
                        </div>

                        <div>
                          <img
                            src={images.chevronRightBlueIcon}
                            alt="page icon"
                            className="me-3"
                          />
                          <span className="fs-18 fw-thin text-black">
                            Capped at NGN&nbsp;2,000.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </SwCard>
                </div>

                <div className="col-md-10 mb-8">
                  <SwCard className="bg-soft-aqua px-md-10 px-lg-12 py-md-8 py-lg-10">
                    <div className="row">
                      <div className="col-md-6">
                        <h2 className="display-6 mb-8">
                          International Transactions
                        </h2>
                        <div className="mb-5">
                          <img
                            src={images.visaCard}
                            alt="page icon"
                            className="mb-3 bank-card-icon"
                          />
                          <img
                            src={images.mastercard}
                            alt="page icon"
                            className="mb-3 ms-3 bank-card-icon"
                          />
                          <img
                            src={images.discoverCard}
                            alt="page icon"
                            className="mb-3 ms-3 bank-card-icon"
                          />
                          <img
                            src={images.amexCard}
                            alt="page icon"
                            className="mb-3 ms-3 bank-card-icon"
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <h2 className="mb-3 display-6">
                          <span className="display-1 fs-65 text-blue mb-3 me-3">
                            4.0%
                          </span>
                          <span className="fs-20 fw-normal">
                            per transaction
                          </span>
                        </h2>

                        <div>
                          <img
                            src={images.chevronRightBlueIcon}
                            alt="page icon"
                            className="me-3"
                          />
                          <span className="fs-18 fw-thin text-black">
                            International — Cards, Gateways
                          </span>
                        </div>
                        <div>
                          <img
                            src={images.chevronRightBlueIcon}
                            alt="page icon"
                            className="me-3"
                          />
                          <span className="fs-18 fw-thin text-black">
                            International cards are charged and settled in your
                            local currency by default
                          </span>
                        </div>
                        <div>
                          <img
                            src={images.chevronRightBlueIcon}
                            alt="page icon"
                            className="me-3"
                          />
                          <span className="fs-18 fw-thin text-black">
                            Uncapped
                          </span>
                        </div>
                      </div>
                    </div>
                  </SwCard>
                </div>
              </div>
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper">
        <div className="container pb-15 pt-10">
          <div className="row text-center">
            <div className="col-lg-12">
              <h3 className="display-3 fw-normal mb-10">
                Transfers in Nigeria
              </h3>
            </div>
            {/* <!-- /column --> */}
          </div>
          {/* <!-- /.row --> */}

          <div className="row gx-md-8 gy-8 justify-content-center">
            <div className="col-md-10">
              <div className="row fs-22 py-8 py-md-10 border-top border-3 border-gray">
                <div className="col-6 col-md-4">Transfer to Bank accounts</div>
                {/* <!--/column --> */}

                {/* <div className="col-6 col-md-6 text-md-end">
                  NGN 45 per transfer
                </div> */}
                <div className="col-6 col-md-8 text-md-end">
                  <div className="row">
                    <div className="col-12 mb-5 text-md-end bg-soft-aqua">
                      NGN&nbsp;15 per transfer of NGN&nbsp;5,000 and below
                    </div>
                    <div className="col-12 mb-5 text-md-end bg-soft-aqua">
                      NGN&nbsp;30 per transfer between NGN&nbsp;5,001 and
                      NGN&nbsp;50,000
                    </div>
                    <div className="col-12 text-md-end bg-soft-aqua">
                      NGN&nbsp;50 per transfer above NGN&nbsp;50,000
                    </div>
                  </div>
                </div>
                {/* <!--/column --> */}
              </div>
              {/* <!--/row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* <!-- /.row --> */}

          <div className="row gx-md-8 gy-8 justify-content-center">
            <div className="col-md-10">
              <div className="row fs-22 py-8 py-md-10 border-top border-3 border-gray">
                <div className="col-6 col-md-6">Transfer to Mobile Money</div>
                {/* <!--/column --> */}

                <div className="col-6 col-md-6 text-md-end">NGN&nbsp;200</div>
                {/* <!--/column --> */}
              </div>
              {/* <!--/row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper bg-soft-blue mt-10 mt-md-0 position-relative">
        <div className="container py-10 py-md-15">
          <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-2">
            <div className="col-lg-5">
              <h3 className="display-4 fw-normal mb-5 pe-xxl-15">
                All-in-one platform
              </h3>
              <p className="fw-normal text-black">
                Because your payment stack got to be powerful regardless of the
                stage of your business
              </p>
            </div>
            {/* <!-- /column --> */}

            <div className="col-md-6 offset-md-1">
              <div className="row">
                <CheckListItem className="col-12 col-md-6">
                  API Integration
                </CheckListItem>

                <CheckListItem
                  className="col-12 col-md-6 md-no-top-border"
                  hasTopBorder
                >
                  Custom Branding
                </CheckListItem>

                <CheckListItem className="col-12 col-md-6" hasTopBorder>
                  Unified Checkout
                </CheckListItem>

                <CheckListItem className="col-12 col-md-6" hasTopBorder>
                  Email Payment Receipt
                </CheckListItem>

                <CheckListItem className="col-12 col-md-6" hasTopBorder>
                  Transaction Reports
                </CheckListItem>

                <CheckListItem className="col-12 col-md-6" hasTopBorder>
                  Webhooks Infrastructure
                </CheckListItem>

                <CheckListItem className="col-12 col-md-6" hasTopBorder>
                  Test Environment
                </CheckListItem>

                <CheckListItem className="col-12 col-md-6" hasTopBorder>
                  Multi-currency Support
                </CheckListItem>

                <CheckListItem className="col-12 col-md-6" hasTopBorder>
                  Central Dashboard
                </CheckListItem>

                <CheckListItem className="col-12 col-md-6" hasTopBorder>
                  Fraud Protection
                </CheckListItem>
              </div>
              {/* <!--/row --> */}
            </div>
            {/* <!--/column --> */}
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}

        {/* <div className="w-100 h-100 bg-blurred-ellipse position-absolute" /> */}
        {/* blurred background */}
      </section>
      {/* <!-- /section --> */}

      <section className="wrapper mt-10">
        <div className="container pb-20 pt-5">
          <div className="row text-center">
            <div className="col-lg-12">
              <h3 id="faqs" className="display-3 fw-normal mb-15">
                FAQs
              </h3>
            </div>
            {/* <!-- /column --> */}

            <div className="col-12">
              <Accordion
                name="faqAccordion"
                accordionItems={[
                  {
                    title:
                      "Do I need to verify my account before I can accept payments?",
                    content: (
                      <p className="text-start">
                        Upon signup, you are expected to have provided the basic
                        information required to set up your account.
                        <br />
                        However, you may be required to verify your account
                        before you can withdraw any funds.
                      </p>
                    ),
                  },
                  {
                    title: "How fast will I get paid?",
                    content: (
                      <p className="text-start">
                        We process withdrawals as fast as we can, depending on
                        the country your business is located in.
                        <br />
                        SwitchApp can also be configured for instant payouts.
                      </p>
                    ),
                  },
                  {
                    title:
                      "Can I register more than one business on SwitchApp?",
                    content: (
                      <p className="text-start">
                        Yes, you have the option of registering multiple
                        businesses on the SwitchApp platform.
                      </p>
                    ),
                  },
                  {
                    title: "How fast are SwitchApp Payouts?",
                    content: (
                      <p className="text-start">
                        Funds sent out from SwitchApp typically takes about 10
                        minutes to be deposited.
                        <br />
                        However, under certain circumstances, it may take
                        longer.
                      </p>
                    ),
                  },
                  {
                    title: "Why is my KYC verification unsuccessful?",
                    content: (
                      <p className="text-start">
                        KYC verification may be unsuccessful for a number of
                        reasons. The most common issues are:
                        <ul>
                          <li>Uploading blurry documents</li>
                          <li>Network issues</li>
                        </ul>
                        If after you have ticked all of the above and the issue
                        still persists, please reach out to the Support team via
                        the chat on your dashboard or email.
                      </p>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          {/* <!-- /.row --> */}
        </div>
        {/* <!-- /.container --> */}
      </section>
      {/* <!-- /section --> */}

      {/* bg-gradient-gray */}
      {/* <!-- /section --> */}
    </div>
  );
};
