import packageJson from "../../package.json";
const APP_PORT = /*packageJson.port ||*/ "3000";

const WEBSITE_URL =
  process.env.NODE_ENV === "development"
    ? `https://localhost:${APP_PORT}`
    : "https://www.switchappgo.com";

const DASHBOARD_URL = "https://dashboard.switchappgo.com";
const API_DOCS_URL = "https://docs.switchappgo.com";
const LIBRARIES_URL = "https://docs.switchappgo.com/libraries";

export const appConstants = {
  appName: "SwitchApp",
  appFooterYear: 2024,
  appCompanyName: "SwitchApp Tech Solutions Ltd",
  appWebsiteHomepageUrl: WEBSITE_URL,
  appContactUrl: `${WEBSITE_URL}/contact`,
  appPrivacyUrl: `${WEBSITE_URL}/privacy`,
  cookiePolicyUrl: `${WEBSITE_URL}/cookie-policy`,
  termsAndConditions: `${WEBSITE_URL}/terms-and-conditions`,
  companyAddress: `16B Alhaji Sunmonu Street, Lekki, Lagos`,

  // dashboard links
  appDashboardUrl: DASHBOARD_URL,
  appSignUpUrl: `${DASHBOARD_URL}/signup`,
  appSignInUrl: `${DASHBOARD_URL}/signin`,
  appDevelopersPage: `${DASHBOARD_URL}/developers`,

  // documentation links
  apiDocumentationUrl: API_DOCS_URL,
  librariesUrl: LIBRARIES_URL,
};
