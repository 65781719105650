import { ReactNode } from "react";

import { images } from "./images";

interface ICheckListItemProps {
  children: ReactNode;
  className?: string;
}

export const CheckListItem = (props: ICheckListItemProps) => {
  return (
    <div className={`mb-3 ${props.className}`}>
      <div className="row justify-content-between">
        <img
          src={images.tickCircleGreenIcon}
          alt="checklist icon"
          className="checklist-icon"
        />
        <div className="col fw-light">{props.children}</div>
      </div>
    </div>
  );
};
