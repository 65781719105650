import { Link } from "react-router-dom";
import { appConstants } from "../../constants";
import { productsRoute } from "../../routes";
import { images } from "./images";

export const FooterLinksRow = () => {
  return (
    <div className="row gy-6 gy-lg-0 mt-20">
      <div className="col-lg-3 mb-3">
        <div className="widget">
          <img className="switchapp-logo mb-4" src={images.footerLogo} alt="" />
          <ul className="list-unstyled mb-0 footer-link-list">
            <li>
              <a href="/about">About</a>
            </li>
            {/* <li>
              <a href="#">Careers</a>
            </li>
            <li>
              <a href="#">Blog</a>
            </li>
            <li>
              <a href="#">Press & Media</a>
            </li> */}
            <li>
              {/* <a href="https://www.switchappgo.com/contact">Contact</a> */}
              <a href="mailto:support@switchappgo.com" target="blank">
                Contact Us
              </a>
            </li>
            {/* <li>
              <a href="#">Investor Relations</a>
            </li> */}
          </ul>
          {/* <!-- /.social --> */}
        </div>
        {/* <!-- /.widget --> */}
      </div>
      <div className="col-6 col-lg-2 offset-lg-1 mb-3">
        <div className="widget">
          <h4 className="widget-title text-white mb-3">Products</h4>
          <ul className="list-unstyled mb-0 footer-link-list">
            <li>
              <Link to={productsRoute.aiForBusinesses}>AI For Businesses</Link>
            </li>
            <li>
              <Link to={productsRoute.payments}>Payments</Link>
            </li>
            <li>
              <Link to={productsRoute.subscriptions}>Subscriptions </Link>
            </li>
            <li>
              <Link to={productsRoute.invoices}>Invoices</Link>
            </li>
            <li>
              <Link to={productsRoute.fraudsAndRisks}>Fraud & risks</Link>
            </li>
            <li>
              <Link to={productsRoute.paymentLinks}>Payment links</Link>
            </li>
            <li>
              <Link to={productsRoute.reports}>Reports</Link>
            </li>
          </ul>
        </div>
        {/* <!-- /.widget --> */}
      </div>
      {/* <!-- /column --> */}
      <div className="col-6 col-lg-2 mb-3">
        <div className="widget">
          <h4 className="widget-title text-white mb-3">Developers</h4>
          <ul className="list-unstyled mb-0 footer-link-list">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${appConstants.apiDocumentationUrl}/payments/transactions`}
              >
                Online Payments APIs
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${appConstants.apiDocumentationUrl}`}
              >
                Documentation
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${appConstants.appDevelopersPage}`}
              >
                Developer Dashboard
              </a>
            </li>
          </ul>
        </div>
        {/* <!-- /.widget --> */}
      </div>
      {/* <!-- /column --> */}
      <div className="col-6 col-lg-2 mb-3">
        <div className="widget">
          <h4 className="widget-title text-white mb-3">Resources</h4>
          <ul className="list-unstyled mb-0 footer-link-list">
            {/* <li>
              <a href="#">Tutorials</a>
            </li>
            <li>
              <a href="#">Meetups</a>
            </li>
            <li>
              <a href="#">Help Center</a>
            </li>
            <li>
              <a href="#">Blog</a>
            </li> */}
            <li>
              <a href="http://localhost:3000/pricing#faqs">FAQS’</a>
            </li>
          </ul>
        </div>
        {/* <!-- /.widget --> */}
      </div>
      {/* <!-- /column --> */}
      <div className="col-6 col-lg-2 mb-3">
        <div className="widget">
          <h4 className="widget-title text-white mb-3">Platform</h4>
          <ul className="list-unstyled mb-0 footer-link-list">
            {/* <li>
              <a href="#">Infrastructure</a>
            </li>
            <li>
              <a href="#">Certifications</a>
            </li>
            <li>
              <a href="#">Licenses</a>
            </li> */}
            <li>
              <a href="/terms-and-conditions">Terms &amp; Conditions</a>
            </li>
            <li>
              <a href="/privacy">Legal</a>
            </li>
          </ul>
        </div>
        {/* <!-- /.widget --> */}
      </div>
      {/* <!-- /column --> */}
    </div>
  );
};
