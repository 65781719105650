import { Link, NavLink } from "react-router-dom";
import { appConstants } from "../../constants";
import { productsRoute } from "../../routes";
import { LinkWithIcon } from "../LinkWithIcon";
import { IHeaderViewProps } from "./Header";

import { images } from "./images";

export const View = (props: IHeaderViewProps) => {
  return (
    <>
      <header className={`wrapper switchapp-header ${props.headerClassName}`}>
        <nav className={`navbar navbar-expand-lg center-nav transparent`}>
          <div className="container flex-lg-row flex-nowrap align-items-center">
            <div className="navbar-brand w-100">
              <Link to="/">
                <img
                  className="logo-dark switchapp-logo"
                  src={images.switchAppLogo}
                  alt="app logo"
                />
                <img
                  className="logo-light switchapp-logo"
                  src={images.switchAppLogo}
                  alt="app logo"
                />
              </Link>
            </div>

            <div className="navbar-collapse offcanvas-nav">
              <div className="offcanvas-header d-lg-none d-xl-none">
                <Link to="/">
                  <img
                    className="logo-light switchapp-logo"
                    src={images.switchAppLogo}
                    alt=""
                  />
                </Link>
                <button
                  type="button"
                  className="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                  aria-label="Close"
                ></button>
              </div>

              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" to={productsRoute.payments}>
                    Products
                  </NavLink>
                  <ul className="dropdown-menu mega-menu">
                    <li className="mega-menu-content">
                      <div className="row">
                        <div className="col-md-6">
                          <LinkWithIcon
                            title="AI for businesses"
                            subtitle="Utilize AI solutions in your business"
                            urlTarget={productsRoute.aiForBusinesses}
                            containerClassName="dropdown-item icon-left--large"
                            iconLeft={
                              <img
                                src={images.aiForBusinessesIcon}
                                alt="frauds and risks icon"
                              />
                            }
                          />
                          <LinkWithIcon
                            title="Payments"
                            subtitle="Online payments"
                            urlTarget={productsRoute.payments}
                            containerClassName="dropdown-item"
                            iconLeft={
                              <img
                                src={images.cardPosIcon}
                                alt="payments icon"
                              />
                            }
                          />
                          <LinkWithIcon
                            title="Subscriptions"
                            subtitle="Subscription management"
                            urlTarget={productsRoute.subscriptions}
                            containerClassName="dropdown-item"
                            iconLeft={
                              <img
                                src={images.cardCoinCycleIcon}
                                alt="subscriptions icon"
                              />
                            }
                          />
                          <LinkWithIcon
                            title="Reports"
                            subtitle="Custom reports"
                            urlTarget={productsRoute.reports}
                            containerClassName="dropdown-item"
                            iconLeft={
                              <img
                                src={images.pieChartIcon}
                                alt="reports icon"
                              />
                            }
                          />
                        </div>
                        {/* <!--/.column --> */}

                        <div className="col-md-6">
                          <LinkWithIcon
                            title="Invoices"
                            subtitle="Online Invoicing"
                            urlTarget={productsRoute.invoices}
                            containerClassName="dropdown-item"
                            iconLeft={
                              <img
                                src={images.noteGreenIcon}
                                alt="Invoices icon"
                              />
                            }
                          />
                          <LinkWithIcon
                            title="Payment links"
                            subtitle="No code payments"
                            urlTarget={productsRoute.paymentLinks}
                            containerClassName="dropdown-item"
                            iconLeft={
                              <img
                                src={images.linkIcon}
                                alt="Payment links icon"
                              />
                            }
                          />
                          <LinkWithIcon
                            title="Fraud & risks"
                            subtitle="Fraud & risk management"
                            urlTarget={productsRoute.fraudsAndRisks}
                            containerClassName="dropdown-item"
                            iconLeft={
                              <img
                                src={images.fraudAndRiskIcon}
                                alt="frauds and risks icon"
                              />
                            }
                          />
                        </div>
                        {/* <!--/.column --> */}
                      </div>
                      {/* <!--/.row --> */}
                    </li>
                    {/* <!--/.mega-menu-content--> */}
                  </ul>
                  {/* <!--/.dropdown-menu --> */}
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/pricing">
                    Pricing
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href={`${appConstants.apiDocumentationUrl}`}
                  >
                    Documentation
                  </a>
                </li>
              </ul>
              {/* <!-- /.navbar-nav --> */}
            </div>
            {/* <!-- /.navbar-collapse --> */}

            <div className="navbar-other w-100 d-flex ms-auto">
              <ul
                className="navbar-nav flex-row align-items-center ms-auto"
                data-sm-skip="true"
              >
                <li className="nav-item d-none d-md-block">
                  <a
                    href={`${appConstants.appDashboardUrl}`}
                    className="nav-link"
                  >
                    Sign in
                  </a>
                </li>
                <li className="nav-item d-none d-md-block">
                  <a
                    href={`${appConstants.appDashboardUrl}/signup`}
                    className="btn btn-md btn-primary rounded"
                  >
                    Request Invitation
                  </a>
                </li>
                {/* <li className="nav-item dropdown language-select text-uppercase">
                  <a
                    className="nav-link dropdown-item dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    En
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a className="dropdown-item" href="#">
                        En
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="dropdown-item" href="#">
                        De
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="dropdown-item" href="#">
                        Es
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li className="nav-item d-lg-none">
                  <div className="navbar-hamburger">
                    <button
                      className="hamburger animate plain"
                      data-toggle="offcanvas-nav"
                      title="nav button"
                    >
                      <span></span>
                    </button>
                  </div>
                </li>
              </ul>
              {/* <!-- /.navbar-nav --> */}
            </div>
            {/* <!-- /.navbar-other --> */}
          </div>
          {/* <!-- /.container --> */}
        </nav>
        {/* <!-- /.navbar --> */}
      </header>
      {/* <!-- /header --> */}
    </>
  );
};
