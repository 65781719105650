import switchAppLogo from "../../assets/img/logo.svg";

// icons
import cardPosIcon from "../../assets/img/icons/card-pos.svg";
import cardCoinCycleIcon from "../../assets/img/icons/card-coin-cycle.svg";
import pieChartIcon from "../../assets/img/icons/pie-chart.svg";
import noteGreenIcon from "../../assets/img/icons/note-green.svg";
import linkIcon from "../../assets/img/icons/link.svg";
import fraudAndRiskIcon from "../../assets/img/icons/fraud-note.svg";
import aiForBusinessesIcon from "../../assets/img/icons/ai-for-businesses.png";

export const images = {
  switchAppLogo,
  cardPosIcon,
  cardCoinCycleIcon,
  pieChartIcon,
  noteGreenIcon,
  linkIcon,
  fraudAndRiskIcon,
  aiForBusinessesIcon,
};
