import { useEffect } from "react";

import { appThemes } from "../../constants";
import { useTheme } from "../../hooks";

import { View } from "./View";

export const Home = () => {
  const { setHeaderClassName, setPageTheme } = useTheme();

  useEffect(() => {
    setHeaderClassName("homepage-header");
    setPageTheme(appThemes.lightBlueTheme);

    return () => setHeaderClassName("");
  }, []);

  return <View />;
};
