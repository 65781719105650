import { ReactNode } from "react";

interface ISwitchAppLocationPointerProps {
  topPercent: number;
  leftPercent: number;
  icon: ReactNode;
  info: ReactNode;
  className?: string;
}

export const SwitchAppLocationPointer = (
  props: ISwitchAppLocationPointerProps
) => {
  return (
    <div
      className={`map-point position-absolute ${props.className}`}
      style={{
        top: `${props.topPercent}%`,
        left: `${props.leftPercent}%`,
      }}
    >
      <div className=" me-3">{props.icon}</div>
      {props.info}
    </div>
  );
};
