import subscriptionSampleScreen from "../../assets/img/sample-screens/subscriptions.png";
import subscriptionDetails from "../../assets/img/photos/subscriptions/subscription-details.png";
import peopleGroup from "../../assets/img/photos/subscriptions/people-group.png";
import laptopTyping from "../../assets/img/photos/subscriptions/laptop-typing.png";

// icons
import cardCoinCycleIcon from "../../assets/img/icons/card-coin-cycle.svg";
import peopleIcon from "../../assets/img/icons/people.svg";
import convertCardIcon from "../../assets/img/icons/convert-card.svg";
import documentTextIcon from "../../assets/img/icons/document-text.svg";
import billIcon from "../../assets/img/icons/bill.svg";

export const images = {
  subscriptionSampleScreen,
  subscriptionDetails,
  peopleGroup,
  laptopTyping,

  cardCoinCycleIcon,
  peopleIcon,
  convertCardIcon,
  documentTextIcon,
  billIcon,
};
