import { ReactNode } from "react";

import { images } from "./images";

interface ICheckListItemProps {
  hasTopBorder?: boolean;
  children: ReactNode;
  className?: string;
}

export const CheckListItem = (props: ICheckListItemProps) => {
  return (
    <div className={`mb-3 px-8 ${props.className}`}>
      <div
        className={`row py-5 justify-content-between ${
          props.hasTopBorder ? "border-top border-pale-blue" : ""
        }`}
      >
        <img
          src={images.tickCircleBlueIcon}
          alt="checklist icon"
          className="checklist-icon"
        />
        <div className="col fw-light">{props.children}</div>
      </div>
    </div>
  );
};
