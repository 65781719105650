import { useTheme } from "../../hooks";

import { View } from "./View";

export interface IHeaderViewProps {
  headerClassName: string;
}

export const Header = () => {
  const { headerClassName } = useTheme();

  return <View headerClassName={headerClassName} />;
};
